import React from 'react';


import { Button, Form, Container, Row, Col, Card } from 'react-bootstrap';
import logo from '../../assets/logo-3.png';
import '../CreateCustomer/index.css';
import API from '../../Api';
import { Redirect } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import loadinggif from '../../assets/loding-gif.gif';
import { Rootcontainer, RootDiv, RootTitle, RootBal, RootRight, RootLeft, RootValue, FooterUl, FooterLi } from './styled'
import {
  MdPowerSettingsNew,
  MdArrowBack
} from 'react-icons/md';
import Drawer from 'react-drag-drawer';
import Undraw from "react-undraw";
import GeneralValues from '../../GobelElements/Values';
import { FaPlus,FaCircle } from "react-icons/fa";
import Loader from 'react-loader-spinner';
export default class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloggedOut: false,
      lcoID: localStorage.getItem("userID"),
      lcoPassword: localStorage.getItem("userPassword"),
      domainName: localStorage.getItem("domain"),
      lcoBalance: "",
      lcoName: "",
      lcocode: "",
      STBNo: props.specifyUnmappedSTB,
      customerNM: "",
      installAddress: "",
      billingaddress: "",
      pinCode: "",
      eMail: "",
      Mobile: "",
      Tell: "",
      isChecked: false,
      customerData: {},
      errorMessage: "",
      isShowLogOutDialog: false,
      image:"",
      inStockListSearchText: "",
      inStockDataList: []

    };
    console.log("STB 1", this.props.specifyUnmappedSTB);
    // this.getDashboardDetails();
    this.getLCO_Details();
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {




    console.log("target value", event.target.value)
    debugger;
    if (/\D/g.test(event.target.value)) {
      console.log("target value", event.target.value)
      event.target.value = event.target.value.replace(/\D/g, '');



    }

    this.setState({ Mobile: event.target.value });

    //this.setState({value: event.target.value});
  }
  addressCheckBox(toggle) {
    debugger

    if (!toggle == true) {
      this.setState({ isChecked: true, installAddress: this.state.billingaddress });
    }
    else {
      this.setState({ isChecked: false, installAddress: "" });
    }
    //this.state.billingaddress : this.state.installAddress
  }
  onSubmit() {

    // debuggerif()
    const { lcoID, lcoPassword, STBNo, customerNM, installAddress, billingaddress, pinCode, eMail, Mobile, Tell, domainName,image } = this.state;
    debugger;
    if (installAddress && billingaddress && pinCode && Mobile ) {
      this.setState({ spinnerVisible: true });


      API.createCustomer(lcoID, lcoPassword, 'createcustomer', STBNo, customerNM, installAddress, billingaddress, pinCode, eMail, Mobile, Tell, domainName,image)
        .then((response) => response.json())
        .then((responsejson) => {
          debugger;

          // this.setState({ customerData: responsejson })

          // console.log("createCustomer", this.state.customerData);
          this.setState({ spinnerVisible: false });
          console.log("response", responsejson);
          debugger;
          this.props.moveToAddPackPage(responsejson.Customernum, this.state.STBNo);




        }).catch(e=>{
          console.log("error",e);
          debugger;

        });
    }
  }
  logOutFunc() {
    if (this.state.isloggedOut) {
      return <Redirect to="/login" />
    } else {
      return null;
    }
  }
  logOut() {

    localStorage.removeItem("userID");
    const username = localStorage.getItem("userID");
    if (!username) {
      this.setState({ isloggedOut: true });
    }
  }
  backTOUnmappedSTB() {
    this.props.moveToBackToUnmappedSTB();
  }

  getLCO_Details() {
    this.setState({ isSpinnerVisible: true })
    const { lcoID, lcoPassword, domainName } = this.state;
    API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
      .then((response) => response.json())
      .then((responsejson) => {
        this.setState({ lcoName: responsejson.lconame, lcoBalance: responsejson.balance, lcocode: responsejson.lcocode, isSpinnerVisible: false });

      });
  }
  stockListSearch(text) {

    var { lcoID, lcoPassword, domainName, inStockListSearchText } = this.state;
    if (text.length > 2) {
        this.setState({ inStockDataList: [], isShowInStockList: true, isShowStockLoader: true, noData: "" });

        API.stockListSearch(lcoID, lcoPassword, "liststock", text, 1, domainName)
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response", responseJson);
                if (responseJson.count) {


                    this.setState({ inStockDataList: responseJson.content, isShowInStockList: true, inStockCurrentPage: 1, totalCountInStock: responseJson.count, isShowStockLoader: false });
                    console.log("In stock list", this.state.inStockDataList);
                } else {
                    this.setState({ inStockDataList: [], isShowStockLoader: false, noData: "No data found!" });
                }
            });
    } else {
        this.setState({ isShowInStockList: false, isShowInStockList: false, isShowStockLoader: false, noData: "" });
    }
}
getSTBDetails(item) {
    console.log("item", item);
    if (item.Customer) {
      this.setState({ spinnerVisible: true });
      const { lcoID, lcoPassword, domainName, searchText } = this.state;
      API.STBSearch(lcoID, lcoPassword, "stbsearch", item.SNo, domainName)
        .then((response) => response.json())
        .then((responsejson) => {
          console.log("response", responsejson);
          if (responsejson.message == "no data") {
            this.setState({ spinnerVisible: false });
          } else {
            localStorage.setItem("currentlco", item.Lcocode);
            this.setState({ spinnerVisible: false });
            this.props.moveToCustomerDetails("customerdetails", responsejson, this.state.searchText);
          }
        });
    }
    else {
      this.setState({ isShowNodataDialog: true })
    }

  }

  render() {
    return (
      <Rootcontainer>
        <LoadingOverlay

          className={this.state.spinnerVisible ? "displaySpinner" : ""}
          overlay={true}
          active={this.state.spinnerVisible}
          spinner={<img src={loadinggif} className="spinnerContent" color="green" />}

        ></LoadingOverlay>

        <Drawer

          open={this.state.isShowLogOutDialog}
          onRequestClose={this.toggle}
          direction='left'
        >

          <Card >


            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 15, fontWeight: 'bold', }}>Do you want <span style={{ fontSize: "1.5em", color: "red" }}>Log out</span> ?</div>
              <Undraw className="undrawimg" name="monitor" />
              <Button
                style={{ marginLeft: 20 }}
                className="success" className="mt-3 pull-right"
                onClick={() => {


                  this.setState({ isShowLogOutDialog: false })

                }}
              >Cancel</Button>

              <Button
                style={{ backgroundColor: "red" }}
                className="danger" className="mt-3 pull-right"
                onClick={() => {

                  // this.props.hindNodataDialog();
                  this.logOut();

                }}
              >OK</Button>



            </Card.Body>
          </Card>

        </Drawer>


        <div className="header-div">
          <MdArrowBack onClick={() => this.backTOUnmappedSTB()} className="backTo" />
          <h3 className="" style={{ marginLeft: '98px' }}>Create Customer</h3>
          <img
            onClick={() => { this.props.moveToDashBoard() }}
            src={GeneralValues.circlelogo} style={{ float: "left", marginLeft: " -180px" }} className="logo-img" alt="logo" />

          <MdPowerSettingsNew onClick={() => { this.setState({ isShowLogOutDialog: true }) }} value="Logout" className="fontSize login" />
          <input type="text" text="Search" placeholder="Global Search..."
                        onChange={(e) => {
                            this.setState({ inStockListSearchText: e.target.value })
                            this.stockListSearch(e.target.value);
                        }}

                        value={this.state.inStockListSearchText}
                        className="input-STB"
                        style={{ backgroundColor: "transparent", borderColor: "transparent", borderBottom: "2px solid white", color: "white", textAlign: "center", fontSize: 15, borderRight: "2px solid white", fontWeight: "bold",alignSelf:"end",marginLeft:"50%" }} />
                    <div style={{
                        position: "absolute",
                        display: this.state.isShowInStockList ? "block" : "none",
                        listStyle: "none",
                        backgroundColor: "white",
                        padding: 0,
                        boxShadow: "0px 1px 3px #e9b42a",
                        zIndex: 9,
                        width: "22vw",
                        top: 60,
                        overflow: "auto",
                        maxHeight: "30vh",
                        marginLeft:"65%"

                    }}
                        onScroll={(e) => this.handleScroll(e)}>
                        <label style={{ fontSize: 15, fontWeight: "bold", color: "rgb(26, 172, 195)", marginLeft: 10 }}>List:</label>
                        <div style={{ textAlign: "center", color: "gray" }}>{this.state.noData}</div>
                        <ul style={{ listStyle: "none", padding: 10 }}>

                            {this.state.inStockDataList.map((item, i) => {

                                return (


                                    <li className="search-list"
                                        onClick={() => { this.getSTBDetails(item) }}
                                        style={{ fontSize: 15, color: "black", lineHeight: 2, borderBottom: "1px solid rgba(26, 172, 195,0.5)", cursor: "pointer" }}>
                                        <div>
                                            <lable style={{ display: "block" }}>
                                                Name:
   {item.Customer ? item.Customer : <FaPlus style={{
                                                    background: " #0a3f65",
                                                    color: "white", width: "20px", height: "20px", padding: "2px", borderRadius: "10px", marginLeft: "8px"
                                                }} />}

                                                <FaCircle style={{
                                                    display: item.status ? "inline-block" : "none",
                                                    height: "12px", float: "right", marginTop: "8px",
                                                    color: item.status == "Live" ? "green" : "red",
                                                }} />


                                            </lable>

                                            <lable style={{ display: "block" }}>STB: {item.SNo}

                                                <div style={{
                                                    fontSize: "12px", display: item.paid ? "inline-block" : "none", float: "right", color: "white",
                                                    padding: "0px 7px", height: "25px",
                                                    backgroundColor: item.paid === "paid" ? "green" : "red",
                                                }}>{item.paid}  </div>
                                            </lable>

                                            <lable style={{ display: "block", fontSize: "12px" }}>Lco: {item.Lconame}

                                                <div style={{ fontSize: "12px", display: "inline-block", float: "right", color: "red" }}>({item.Lcocode})
  </div>
                                            </lable>
                                        </div>
                                    </li>

                                );
                            })}
                        </ul>

                        <Loader
                            visible={this.state.isShowStockLoader}
                            style={{ textAlign: "center", marginBottom: 10, }}
                            type="ThreeDots"
                            color="#00BFFF"
                            height="10"
                            width="20"
                        />
                    </div>

        </div>

        <RootDiv>
          <RootLeft>
            <RootTitle>
              Name :
                    </RootTitle>

            <RootValue
              style={{ cursor: "pointer" }}
              onClick={() => { this.props.onClickProfile() }} >
              {this.state.lcoName}

            </RootValue>
            <div className="lcocode" >

              (<span style={{ color: "black" }}>Lco code:</span> {this.state.lcocode})
                </div>
          </RootLeft>

          <RootRight>
            <RootTitle>
              Balance :
</RootTitle>

            <RootValue>
              {'\u20B9'} {this.state.lcoBalance}
            </RootValue>

          </RootRight>
        </RootDiv>
        <div className="mt-3">

          <Form className="form-div">
            <h3>Create Customer</h3>
            <Row>
              <Col xs={12} md={4} lg={3} sm={6} >
                <Form.Group>
                  <Form.Label>Name <span>*</span></Form.Label>
                  <Form.Control type="text" placeholder="Enter Name" required onChange={(e) => { this.setState({ customerNM: e.target.value }) }}

                    value={this.state.customerNM} />
                </Form.Group>
              </Col>
              <Col xs={12} md={4} lg={3} sm={6} >
                <Form.Group>
                  <Form.Label>Billing Address <span>*</span></Form.Label>
                  <Form.Control type="text" placeholder="Enter Billing Address" required onChange={(e) => { this.setState({ billingaddress: e.target.value }) }}

                    value={this.state.billingaddress} />
                </Form.Group>

              </Col>
              <Col xs={12} md={4} lg={3} sm={6} className="mt-5">
                <Form.Group controlId="formBasicChecbox">
                  <Form.Check type="checkbox" label="Installation address same as Billing Address" checked={this.state.isChecked} onClick={(e) => this.addressCheckBox(this.state.isChecked)} />
                </Form.Group>
              </Col>
              <Col xs={12} md={4} lg={3} sm={6} >
                <Form.Group>
                  <Form.Label>Installation Address <span>*</span></Form.Label>
                  <Form.Control type="text" placeholder="Enter Installation Address" required onChange={(e) => { this.setState({ installAddress: e.target.value }) }}

                    value={this.state.isChecked ? this.state.billingaddress : this.state.installAddress} />
                </Form.Group>

              </Col>
              <Col xs={12} md={4} lg={3} sm={6} >
                <Form.Group>
                  <Form.Label>Pincode <span>*</span></Form.Label>
                  <Form.Control type="number" placeholder="Enter Pincode" required onChange={(e) => { this.setState({ pinCode: e.target.value }) }}

                    value={this.state.pinCode} />
                </Form.Group>

              </Col>
              <Col xs={12} md={4} lg={3} sm={6} >
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control type="email" placeholder="Enter Email ID" onChange={(e) => { this.setState({ eMail: e.target.value }) }}

                    value={this.state.eMail} />

                </Form.Group>

              </Col>
              <Col xs={12} md={4} lg={3} sm={6} >
                <Form.Group>
                  <Form.Label>Mobile No <span>*</span></Form.Label>
                  <Form.Control type="text" placeholder="Enter Mobile No" maxLength="10" minLength="10" required onChange={this.handleChange}

                    value={this.state.Mobile} />
                </Form.Group>

              </Col>
              <Col xs={12} md={4} lg={3} sm={6} >
                <Form.Group>
                  <Form.Label>Telephone No</Form.Label>
                  <Form.Label> </Form.Label><Form.Control type="number" placeholder="Enter Telephone No" onChange={e => { this.setState({ Tell: e.target.value }) }} value={this.state.Tell} />
                </Form.Group>

              </Col>

              <Col xs={12} md={4} lg={3} sm={6} >
                <Form.Group>
                  <Form.Label>Proof </Form.Label>
                  <input
                    id="car"
                    type="file"
                    accept="image/*"
                    // capture="camera"
                    onChange={(evt) => {
                      console.log("Uploading");
                      var self = this;
                      var reader = new FileReader();
                      var file = evt.target.files[0];

                      reader.onload = function (upload) {
                        self.setState({
                          image: upload.target.result
                        });
                      };
                      reader.readAsDataURL(file);
                      setTimeout(function() {
                        console.log(self.state.image);
                        debugger;
                      }, 1000);
                      console.log("Uploaded");
                      debugger;
                    }}
                  />

                </Form.Group>

              </Col>
            </Row>
            <Button
              onClick={() => this.onSubmit()}
              // variant="success"
              style={{backgroundColor:GeneralValues.mustard,borderWidth:0}}
            >
              Submit
  </Button>
          </Form>
        </div>


        {this.logOutFunc()}
      </Rootcontainer>


    )
  }
}