import React from 'react';
import {
    Button, ButtonGroup, Container, ButtonToolbar, Jumbotron, Card, Form, Row, Col,
    Dropdown, DropdownButton, Modal
} from 'react-bootstrap';
import {
    Rootcontainer, SearchDiv, MainContainer, RootDiv, RootTitle, RootBal, RootRight, Amt, MoreDetails,
    RootLeft, RootValue, SelectedDiv, SelectedPackage, FooterUl, FooterLi
} from './styled';
import { Redirect } from 'react-router-dom';
import logo from '../../../assets/logo-3.png';
import LoadingOverlay from 'react-loading-overlay';
import Checkboxs from 'muicss/lib/react/checkbox';
import { CSVLink, CSVDownload } from "react-csv";
import { FaPlus, FaCircle } from "react-icons/fa";
import Loader from 'react-loader-spinner';
import {
    MdPowerSettingsNew,
    MdArrowBack,
    MdClose
} from 'react-icons/md';
import './index.css';
// import DialogBoxs from './DialogBoxs';
import API from '../../../Api';
import DatePicker from 'react-date-picker';
import Calendar from 'react-calendar';
// import Date from './Date';
import CSVReader from "react-csv-reader";
import loadinggif from '../../../assets/loding-gif.gif';
import DialogBoxes from './DialogBoxes';
import GeneralValues from '../../../GobelElements/Values';
// import date from 'date-and-time';
// import {samples} from './CSVSample';



export default class CustomerTransfer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isloggedOut: false,
            isShowLogOutWarning: false,
            lcoID: localStorage.getItem("userID"),
            lcoPassword: localStorage.getItem("userPassword"),
            domainName: localStorage.getItem("domain"),
            lcoList: JSON.parse(localStorage.getItem("lcolist")),
            isSpinnerVisible: false,
            isCSVOperation: false,
            UnmappedSTBList: [],
            currentPageNo: 1,
            totalCount: 0,
            CSVLength: 0,
            selectedSTB: [],
            selectedItemsCount: 0,
            searchText: "",
            modData: [],
            lcoSearchResult: [],
            isShowLcoSearchDialog: false,
            selectedLcoCode: "",
            noOfcompletedData: 0,
            noOfDataGoingToProcess: 0,
            unProcessedData: [],
            isShowProcessDialog: false,
            isShowUnProcessedDataListDialog: false,
            isEmptyList: false,
            CSVDataList: [],
            // CSVLength:0,
            CSVError: "",
            CSVSelectedData: [],
            isShowTransferWarningCSV: false,
            totalItemCount: 0,
            textGoingToSearch: "",
            CSVDataListDummy: [],
            noOfCSVFileUploaded: 0,
            numberOfRowsInCsv: 0,
            csvErrorUpload: [],
            noOfCSVRowsFinished: 0,
            isShowCSVLoadDialog: false,
            isShowUnProcessCSVList: false,
            inStockListSearchText: "",
            inStockDataList: []



        }

        let arr = [];
        for (var i = 0; i < this.state.lcoList.length; i++) {
            arr.push({ "id": this.state.lcoList[i].lconame + " " + this.state.lcoList[i].lcocode, "value": this.state.lcoList[i].lcocode, "name": this.state.lcoList[i].lconame })
        }
        console.log("data in kk", arr);
        this.state.modData = arr;
        this.state.lcoSearchResult = arr;
        this.setState({ modData: arr, lcoSearchResult: arr });
    }
    componentWillMount() {
        this.getMSOUnmappedSTB();
    }
    hindEmptyList() {
        this.setState({ isEmptyList: false });
    }
    hindLcoSearchDialog() {
        this.setState({ isShowLcoSearchDialog: false });
    }
    hindCSVLoadDialog() {
        this.setState({ isShowCSVLoadDialog: false });
        if (this.state.csvErrorUpload.length) {
            this.setState({ isShowUnProcessCSVList: true });
        }
    }
    hindUnProcessCSVList() {
        this.setState({ isShowUnProcessCSVList: false, csvErrorUpload: [] });
    }
    hindProcessDialog() {
        this.setState({ isShowProcessDialog: false, selectedSTB: [], isCSVOperation: false, CSVDataList: [], CSVSelectedData: [] });
        if (this.state.unProcessedData.length) {
            this.setState({ isShowUnProcessedDataListDialog: true });
        } else {
            this.getMSOUnmappedSTB();
        }
    }
    hindTransferWarningCSV() {
        this.setState({ isShowTransferWarningCSV: false });
    }
    hindUnProcessedDataListDialog() {
        this.setState({ isShowUnProcessedDataListDialog: false });
        this.getMSOUnmappedSTB();
    }

    apiFetchForSTBDetails(i, STBNo, toLco) {
        console.log("reposene", i, STBNo, toLco);
        debugger;
        const { lcoID, lcoPassword, domainName } = this.state;
        API.stbDetails(lcoID, lcoPassword, "stbdetail", STBNo, domainName)
            .then((response) => response.json())
            .then((response) => {
                console.log("data", response)
                if (!response.error) {
                    var data = response;
                    data.toggleMode = true;
                    data.toLco = toLco;
                    var oldData = this.state.CSVDataList;
                    oldData.push(data);
                    this.setState({ CSVDataList: oldData, noOfCSVRowsFinished: i + 1 });

                    if ((this.state.numberOfRowsInCsv - 1) >= i) {
                        this.apiFetchForSTBDetails(i + 1, this.state.CSVDataListDummy[i + 1].STBNo, this.state.CSVDataListDummy[i + 1].toLco)

                    } else {

                    }
                } else {
                    var kData = this.state.csvErrorUpload;
                    var n = { "error": response.error, "STBNo": STBNo }
                    kData.push(n);
                    this.setState({ noOfCSVRowsFinished: i + 1, csvErrorUpload: kData })
                    if ((this.state.numberOfRowsInCsv - 1) >= i) {
                        this.apiFetchForSTBDetails(i + 1, this.state.CSVDataListDummy[i + 1].STBNo, this.state.CSVDataListDummy[i + 1].toLco)

                    } else {

                    }


                }
                debugger;
            }).catch((error) => {
                console.log("error", error);
                debugger;
            })



    }
    handleCSVFileUpload = e => {
        this.setState({ CSVDataListDummy: [], CSVDataList: [] });
        var arrayOfData = [];
        console.log("csv", e);
        var len = e.length;
        for (var i = 1; i < len; i++) {
            if (e[i][0]) {
                var arr = e[i];
                var csvData = {
                    STBNo: arr[0],
                    toLco: arr[1],
                    toggleMode: true

                }
                arrayOfData.push(csvData);
            } else {
                console.log("data not found", e[i]);
            }
        }
        console.log("Array of data", arrayOfData, arrayOfData.length);
        if (!arrayOfData.length) {
            debugger;
            this.setState({ CSVError: "No data In CSV file", CSVDataListDummy: [] });
        } else {
            debugger;
            this.setState({ CSVError: "", CSVDataListDummy: arrayOfData, numberOfRowsInCsv: arrayOfData.length, isShowCSVLoadDialog: true });
            this.apiFetchForSTBDetails(0, this.state.CSVDataListDummy[0].STBNo, this.state.CSVDataListDummy[0].toLco)
        }

    }

    recursiveFuncCSV(data) {
        console.log("data", data);
        debugger;
        var completed = this.state.noOfcompletedData;
        var total = this.state.noOfDataGoingToProcess;
        if (total >= completed) {
            const { lcoID, lcoPassword, domainName, selectedLcoCode } = this.state;
            API.customerTransfer(lcoID, lcoPassword, "customertransfer", data.Customernum, data.toLco, domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    var n = this.state.noOfcompletedData;
                    console.log("response", responseJson, n, total);
                    this.setState({ noOfcompletedData: n + 1 });
                    if (responseJson.message) {
                        debugger;


                        if ((n + 1) <= total) {
                            debugger;
                            this.recursiveFuncCSV(this.state.CSVSelectedData[n + 1])
                        }
                    } else {
                        debugger;
                        var data1 = this.state.unProcessedData;
                        data1.push({ result: responseJson.error });
                        this.setState({ unProcessedData: data1 });
                        if ((n + 1) <= total) {
                            this.recursiveFuncCSV(this.state.CSVSelectedData[n + 1])
                        }
                    }

                }).catch((error) => {
                    console.log("Error", error);
                })
        }
    }


    transferFuncCSV() {
        var newCSVList = [];
        var previousCSVDataList = this.state.CSVDataList;
        for (var i in previousCSVDataList) {
            if (previousCSVDataList[i].toggleMode) {
                newCSVList.push(previousCSVDataList[i]);
            }
        }
        if (newCSVList.length) {


            this.setState({
                isShowLcoSearchDialog: false,
                noOfDataGoingToProcess: newCSVList.length,
                noOfcompletedData: 0,
                isShowProcessDialog: true,
                CSVSelectedData: newCSVList
            });
            this.state.CSVSelectedData = newCSVList;
            this.state.noOfDataGoingToProcess = newCSVList.length;
            this.state.noOfcompletedData = 0;
            console.log("new ", this.state.CSVSelectedData);

            debugger;

            this.recursiveFuncCSV(this.state.CSVSelectedData[0]);

        }



    }

    recursiveFunc(data) {
        var completed = this.state.noOfcompletedData;
        var total = this.state.noOfDataGoingToProcess;
        if (total >= completed) {
            const { lcoID, lcoPassword, domainName, selectedLcoCode } = this.state;
            API.customerTransfer(lcoID, lcoPassword, "customertransfer", data.CustomerNum, selectedLcoCode, domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    var n = this.state.noOfcompletedData;
                    console.log("response", responseJson, n, total);
                    this.setState({ noOfcompletedData: n + 1 });
                    if (responseJson.message) {
                        debugger;


                        if ((n + 1) <= total) {
                            debugger;
                            this.recursiveFunc(this.state.selectedSTB[n + 1])
                        }
                    } else {
                        var data1 = this.state.unProcessedData;
                        data1.push({ result: responseJson.error });
                        this.setState({ unProcessedData: data1 });
                        if ((n + 1) <= total) {
                            this.recursiveFunc(this.state.selectedSTB[n + 1])
                        }
                    }

                }).catch((error) => {
                    console.log("Error", error);
                })
        }
    }


    transferFunc(lcocode) {
        console.log("data loc code", lcocode);


        this.setState({
            isShowLcoSearchDialog: false,
            noOfDataGoingToProcess: this.state.selectedSTB.length,
            noOfcompletedData: 0,
            selectedLcoCode: lcocode,
            isShowProcessDialog: true
        });
        this.state.selectedLcoCode = lcocode;
        this.state.noOfDataGoingToProcess = this.state.selectedSTB.length;
        this.state.noOfcompletedData = 0;
        console.log("data loc code", lcocode, this.state.selectedLcoCode);
        debugger;

        this.recursiveFunc(this.state.selectedSTB[0]);





    }
    lcoSearchFunc(text) {
        var len = text.length;
        console.log("length", len, this.state.modData[0].id);
        if (len) {
            var dummy = [];
            var length = this.state.lcoList.length;
            var searchText = text.toString().toLowerCase();
            console.log("data", length, len);
            for (var i = 0; i < length; i++) {

                var val = ""
                val = this.state.modData[i].id.toString().toLowerCase();
                // var lower = val.toString();
                // console.log("lower",val,searchText);

                var n = val.search(searchText);
                if (n != -1) {
                    dummy.push(this.state.modData[i]);
                }
            }
            console.log("dummy", dummy);
            this.setState({ lcoSearchResult: dummy });
        }
        // else{
        //   this.setState({isShowLcoList:false});
        // }
    }
    nextPageData(data, pageNo) {
        let lengthOfACustomerList = this.state.UnmappedSTBList.length;
        console.log("lenght of the list", this.state.totalCount);
        if (lengthOfACustomerList < this.state.totalCount) {

            this.setState({ isShowLoader: true })
            const { lcoID, lcoPassword, domainName, } = this.state;
            var search = "mappedstb disconnect" + this.state.textGoingToSearch
            API.getMSOUnmappedSTB(lcoID, lcoPassword, "liststock", search, pageNo, domainName)

                .then((response) => response.json())
                .then((responsejson) => {
                    if (responsejson.count) {
                        console.log("SearchResponse", responsejson);
                        let oldData = data;

                        let count = this.state.pageCount + 1;
                        var arraydata = Object.keys(responsejson.content).reduce((prevVal, key) => {
                            let o = { ...responsejson.content[key] };
                            prevVal.push(o);

                            return prevVal;
                        }, []);
                        let newData = oldData.concat(arraydata);

                        this.setState({ UnmappedSTBList: newData, currentPageNo: count, isShowLoader: false });
                        // console.log("data", this.state.customerList);
                        // if (newData.length < responsejson.count) {

                        //     this.nextPageData(this.state.customerList, (this.state.pageCount + 1));
                        // }
                    }
                })
                .catch((err) => {

                    console.log("error", err);
                    this.setState({ isSpinnerVisible: false });
                    // break


                });
        }
    }
    searchForSTB() {
        this.setState({ isSpinnerVisible: true, currentPageNo: 1, UnmappedList: [] });
        const { lcoID, lcoPassword, domainName } = this.state;
        var dataText = this.state.searchText;
        var search = "mappedstb disconnect " + dataText;
        API.getMSOUnmappedSTB(lcoID, lcoPassword, "liststock", search, 1, domainName)
            .then((resposne) => resposne.json())
            .then((responseJson) => {
                console.log("response Json", responseJson);
                if (responseJson.content) {

                    var arraydata = Object.keys(responseJson.content).reduce((prevVal, key) => {
                        var toggleMode1 = false;
                        for (var j = 0; j < this.state.selectedSTB.length; j++) {
                            if (responseJson.content[key].SNo == this.state.selectedSTB[j].SNo) {
                                toggleMode1 = true
                                break;
                            }
                        }
                        let o = { ...responseJson.content[key], toggleMode: toggleMode1 };
                        prevVal.push(o);

                        return prevVal;
                    }, []);

                    this.setState({ isSpinnerVisible: false, UnmappedSTBList: arraydata, totalCount: responseJson.count, isShowLoader: false, totalItemCount: responseJson.count });
                } else {
                    this.setState({ isSpinnerVisible: false, UnmappedSTBList: [], totalCount: 0, isShowLoader: false, totalItemCount: 0 });
                }
            }).catch((error) => {
                console.log("error", error);
                this.setState({ isSpinnerVisible: false, isShowLoader: false });
            })
    }

    getMSOUnmappedSTB() {
        this.setState({ isSpinnerVisible: true, currentPageNo: 1 });
        const { lcoID, lcoPassword, domainName } = this.state;
        API.getMSOUnmappedSTB(lcoID, lcoPassword, "liststock", "mappedstb disconnect", 1, domainName)
            .then((resposne) => resposne.json())
            .then((responseJson) => {
                console.log("response Json", responseJson);
                if (responseJson.content) {


                    var arraydata = Object.keys(responseJson.content).reduce((prevVal, key) => {
                        let o = { ...responseJson.content[key], };
                        prevVal.push(o);

                        return prevVal;
                    }, []);
                    console.log("toogle", arraydata);

                    this.setState({ isSpinnerVisible: false, UnmappedSTBList: arraydata, totalCount: responseJson.count, isShowLoader: false, totalItemCount: responseJson.count });
                } else {
                    this.setState({ isSpinnerVisible: false, UnmappedSTBList: [], totalCount: 0, isShowLoader: false, totalItemCount: 0 });
                }
            }).catch((error) => {
                console.log("error", error);
                this.setState({ isSpinnerVisible: false, isShowLoader: false });
            })
    }

    hindLogOutDialog() {
        this.setState({ isShowLogOutWarning: false });
    }

    logOutFunc() {
        if (this.state.isloggedOut) {
            return <Redirect to="/login" />
        } else {
            return null;
        }
    }
    logOut() {

        localStorage.removeItem("userID");
        const username = localStorage.getItem("userID");
        if (!username) {
            this.setState({ isloggedOut: true });
        }
    }
    toggleChangeInSelectionCSV(i, data) {
        var oldDataArray = this.state.CSVDataList;
        var toggleMode = !data.toggleMode;
        oldDataArray[i].toggleMode = toggleMode;
        this.setState({ CSVDataList: oldDataArray })
    }
    handleScroll(e) {
        // console.log("scorller",e.target.scrollHeight,e.target.scrollTop,e.target.clientHeight);
        const bottom = e.target.scrollHeight - (e.target.scrollTop) === e.target.clientHeight;
        console.log("lco", bottom);
        if (bottom) {

            this.nextPageData(this.state.UnmappedSTBList, (this.state.currentPageNo + 1));
        }
    }

    toggleChangeInSelection(index, data) {

        let oldDataList = this.state.UnmappedSTBList;
        let oldToggleMode = oldDataList[index].toggleMode;
        oldDataList[index].toggleMode = !oldToggleMode;
        this.setState({ UnmappedSTBList: oldDataList });

        var selectedList = this.state.selectedSTB;


        if (oldDataList[index].toggleMode) {



            selectedList.push(oldDataList[index]);
            console.log("Selected STB", this.state.selectedSTB);
            this.setState({ selectedSTB: selectedList, selectedItemsCount: selectedList.length });



        }
        else {
            var data1 = this.state.selectedSTB;
            for (var i = 0; i < data1.length; i++) {
                if (data.SNo == data1[i].SNo) {
                    data1.splice(i, 1);
                    break;
                }



            }
            this.setState({ selectedSTB: data1, selectedItemsCount: data1.length });

        }

        // this.setState({selectedItemsCount : this.state.selectedSTB.length});

    }
    removeSTB(data) {



        //oldValue=(parseFloat(oldDataList[index].topay).toFixed(2));
        var datalist = this.state.selectedSTB
        for (var i = 0; i < datalist.length; i++) {

            if (data.SNo == datalist[i].SNo) {

                datalist.splice(i, 1);

                this.setState({ selectedSTB: datalist, selectedItemsCount: datalist.length });

                break;
            }

        }
        var UnmappedList = this.state.UnmappedSTBList;
        for (i = 0; i < UnmappedList.length; i++) {
            if (UnmappedList[i].SNo == data.SNo) {

                let oldToggleMode = UnmappedList[i].toggleMode;
                UnmappedList[i].toggleMode = !oldToggleMode;
                this.setState({ customerList: UnmappedList });
            }
        }
        this.setState({ selectedItemsCount: this.state.selectedSTB.length });
    }
    showSearch() {
        return (
            <div className="stb-search" style={{ marginRight: 15, marginTop: 15 }}>
                <input
                    placeholder="STB Search...."
                    value={this.state.searchText}
                    onChange={(e) => { this.setState({ searchText: e.target.value }) }}
                    onKeyPress={(e) => {

                        if (e.keyCode == 13 || e.which == 13) {
                            this.setState({ textGoingToSearch: e.target.value });
                            this.searchForSTB();
                        }
                    }} />

                <Button className="btn-primary"
                    onClick={() => {
                        this.setState({ textGoingToSearch: this.state.searchText })
                        this.searchForSTB()
                    }}
                    style={{ marginLeft: 15 }}>Search</Button>
            </div>
        );
    }

    showContain() {
        if (this.state.isCSVOperation) {
            return (
                <table className="table table-striped">

                    <div style={{ textAlign: "center", color: "red" }}>{this.state.CSVError}</div>

                    <thead>
                        <th style={{ width: "3%" }}></th>
                        <th> STB </th>
                        <th >LCO Name</th>
                        <th>Customer Name</th>
                        <th>Address</th>
                        <th>Status</th>
                        <th>To Lco</th>




                    </thead>
                    <tbody className="total-records">
                        {this.state.CSVDataList.map((data, i) => {
                            return (
                                <tr>
                                    <td style={{ width: "3%" }}>


                                        <Checkboxs
                                            style={{ fontSize: 20, color: "green" }}
                                            color="green"

                                            onChange={() => { this.toggleChangeInSelectionCSV(i, data) }}
                                            checked={data.toggleMode} />
                                    </td>



                                    <td>{data.SNo}</td>
                                    <td >{data.Lconame ? data.Lconame : "None"}</td>

                                    <td>{data.Customer ? data.Customer : "None"}</td>
                                    <td>{data.Address ? data.Address : "None"}</td>
                                    <td>{data.status} </td>
                                    <td>{data.toLco}</td>


                                </tr>
                            )
                        })}
                    </tbody>



                </table>
            );
        } else {

            return (

                <table className="table table-striped">
                    {this.showSearch()}

                    <div>Selected List :  <label style={{ marginLeft: "60%" }}>Selected Items: <span style={{ color: "red", fontWeight: "bold" }}>{this.state.selectedSTB.length}</span>  No of Result:  <span style={{ color: "red", fontWeight: "bold" }}>{this.state.totalItemCount}</span></label> </div>

                    <SelectedDiv className="selected-div text-left">


                        {this.state.selectedSTB.map((data, i) => {
                            return (

                                <SelectedPackage className="mr-3">{data.SNo} <MdClose onClick={(e) => this.removeSTB(data)} /></SelectedPackage>


                            );
                        }
                        )}
                    </SelectedDiv>

                    <div style={{ textAlign: "center", color: "red", fontWeight: "bold" }}>{this.state.isEmptyCSVList}</div>
                    <thead>
                        <th style={{ width: "3%" }}></th>
                        <th> STB </th>
                        <th >LCO Name</th>
                        <th>Customer Name</th>
                        <th>Address</th>
                        <th>Status</th>



                    </thead>
                    <tbody className="total-records">
                        {this.state.UnmappedSTBList.map((data, i) => {
                            return (
                                <tr>
                                    <td style={{ width: "3%" }}>


                                        <Checkboxs
                                            style={{
                                                fontSize: 20, color: "green",
                                                width: "20px",
                                                height: " 20px "
                                            }}
                                            color="green"

                                            onChange={() => { this.toggleChangeInSelection(i, data) }}
                                            checked={data.toggleMode} />
                                    </td>



                                    <td>{data.SNo}</td>
                                    <td >{data.Lconame ? data.Lconame : "None"}</td>

                                    <td>{data.Customer ? data.Customer : "None"}</td>
                                    <td>{data.Address ? data.Address : "None"}</td>
                                    <td>{data.status} / {data.paid ? data.paid : "None"}</td>

                                </tr>
                            )
                        })}
                    </tbody>

                    <Loader
                        visible={this.state.isShowLoader}
                        style={{ textAlign: "center" }}
                        type="ThreeDots"
                        color="#00BFFF"
                        height="20"
                        width="100"
                    />


                </table>
            );
        }
    }

    csvData = [
        ["STB",
            "LCO ID",
        ],

    ];
    isShowCSVOption() {
        if (this.state.isCSVOperation) {
            return (
                <div>
                    <CSVReader

                        style={{ border: "2px solid #ececec", padding: 5, }}
                        cssClass="react-csv-input"
                        onFileLoaded={e => { this.handleCSVFileUpload(e) }}
                    />
                    <label style={{ fontSize: 15 }}>Total STB in CSV: <label style={{ fontSize: 18, fontWeight: "bold", color: "#d61818" }}>{this.state.CSVDataList.length}</label></label>



                    <CSVLink
                        filename={`CustomerTransfer(${this.state.lcoID}).csv`}
                        data={this.csvData} style={{ display: "block" }}>Download sample file</CSVLink>
                    <Button
                        style={{ borderColor: "green", backgroundColor: "green", marginTop: 10, marginBottom: 10 }}
                        onClick={() => {
                            if (this.state.CSVDataList.length) {
                                this.setState({ isShowTransferWarningCSV: true });
                            } else {
                                this.setState({ isEmptyList: true });
                            }

                            // debugger;
                            // this.setState({isShowTransferWarningCSV:true})
                        }}
                    >Transfer</Button>
                </div>
            );
        } else {
            return (

                <div style={{
                    marginBottom: "-46px",
                    marginTop: "20px",
                    marginLeft: "64px",

                }}>
                    <Button className="btn-success"
                        onClick={() => {
                            if (this.state.selectedSTB.length) {
                                this.setState({ isShowLcoSearchDialog: true });
                            } else {
                                this.setState({ isEmptyList: true });
                            }

                        }
                        }
                    >Select Lco</Button>
                </div>
            );
        }
    }
    toggleInCSV() {
        var data = this.state.isCSVOperation;
        this.setState({ isCSVOperation: !data, CSVError: "", CSVDataList: [] });
    }
    stockListSearch(text) {

        var { lcoID, lcoPassword, domainName, inStockListSearchText } = this.state;
        if (text.length > 2) {
            this.setState({ inStockDataList: [], isShowInStockList: true, isShowStockLoader: true, noData: "" });

            API.stockListSearch(lcoID, lcoPassword, "liststock", text, 1, domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    if (responseJson.count) {


                        this.setState({ inStockDataList: responseJson.content, isShowInStockList: true, inStockCurrentPage: 1, totalCountInStock: responseJson.count, isShowStockLoader: false });
                        console.log("In stock list", this.state.inStockDataList);
                    } else {
                        this.setState({ inStockDataList: [], isShowStockLoader: false, noData: "No data found!" });
                    }
                });
        } else {
            this.setState({ isShowInStockList: false, isShowInStockList: false, isShowStockLoader: false, noData: "" });
        }
    }
    getSTBDetails(item) {
        console.log("item", item);
        if (item.Customer) {
            this.setState({ spinnerVisible: true });
            const { lcoID, lcoPassword, domainName, searchText } = this.state;
            API.STBSearch(lcoID, lcoPassword, "stbsearch", item.SNo, domainName)
                .then((response) => response.json())
                .then((responsejson) => {
                    console.log("response", responsejson);
                    if (responsejson.message == "no data") {
                        this.setState({ spinnerVisible: false });
                    } else {
                        localStorage.setItem("currentlco", item.Lcocode);
                        this.setState({ spinnerVisible: false });
                        this.props.moveToCustomerDetails("customerdetails", responsejson, this.state.searchText);
                    }
                });
        }
        else {
            this.setState({ isShowNodataDialog: true })
        }

    }

    render() {
        return (
            <Rootcontainer
                onScroll={(e) => this.handleScroll(e)}
            // ref={this.nav}
            //onScrollCapture={(e)=>{  this.nextPageData(this.state.customerList, (this.state.pageCount + 1))}}
            >

                <LoadingOverlay

                    className={this.state.isSpinnerVisible ? "displaySpinner" : ""}
                    overlay={true}
                    active={this.state.isSpinnerVisible}
                    spinner={<img src={loadinggif} className="spinnerContent" color="green" />}

                ></LoadingOverlay>


                <div className="header-div">
                    <MdArrowBack onClick={() => this.props.backToStockOperation()} className="back-button" style={{ marginRight: 15 }} />
                    <h3 className="" style={{ marginLeft: '98px' }}>Customer Transfer</h3>
                    <img
                        onClick={() => { this.props.moveToDashBoard() }}
                        src={GeneralValues.circlelogo} style={{ float: "left", marginLeft: " -180px" }} className="logo-img" alt="logo" />

                    <MdPowerSettingsNew onClick={() => { this.setState({ isShowLogOutWarning: true }) }} value="Logout" className="fontSize login" />
                    <input type="text" text="Search" placeholder="Global Search..."
                        onChange={(e) => {
                            this.setState({ inStockListSearchText: e.target.value })
                            this.stockListSearch(e.target.value);
                        }}

                        value={this.state.inStockListSearchText}
                        className="input-STB"
                        style={{ backgroundColor: "transparent", borderColor: "transparent", borderBottom: "2px solid white", color: "white", textAlign: "center", fontSize: 15, borderRight: "2px solid white", fontWeight: "bold", alignSelf: "end", marginLeft: "50%" }} />
                    <div style={{
                        position: "absolute",
                        display: this.state.isShowInStockList ? "block" : "none",
                        listStyle: "none",
                        backgroundColor: "white",
                        padding: 0,
                        boxShadow: "0px 1px 3px #e9b42a",
                        zIndex: 9,
                        width: "22vw",
                        top: 60,
                        overflow: "auto",
                        maxHeight: "30vh",
                        marginLeft: "65%"

                    }}
                        onScroll={(e) => this.handleScroll(e)}>
                        <label style={{ fontSize: 15, fontWeight: "bold", color: "rgb(26, 172, 195)", marginLeft: 10 }}>List:</label>
                        <div style={{ textAlign: "center", color: "gray" }}>{this.state.noData}</div>
                        <ul style={{ listStyle: "none", padding: 10 }}>

                            {this.state.inStockDataList.map((item, i) => {

                                return (


                                    <li className="search-list"
                                        onClick={() => { this.getSTBDetails(item) }}
                                        style={{ fontSize: 15, color: "black", lineHeight: 2, borderBottom: "1px solid rgba(26, 172, 195,0.5)", cursor: "pointer" }}>
                                        <div>
                                            <lable style={{ display: "block" }}>
                                                Name:
  {item.Customer ? item.Customer : <FaPlus style={{
                                                    background: " #0a3f65",
                                                    color: "white", width: "20px", height: "20px", padding: "2px", borderRadius: "10px", marginLeft: "8px"
                                                }} />}

                                                <FaCircle style={{
                                                    display: item.status ? "inline-block" : "none",
                                                    height: "12px", float: "right", marginTop: "8px",
                                                    color: item.status == "Live" ? "green" : "red",
                                                }} />


                                            </lable>

                                            <lable style={{ display: "block" }}>STB: {item.SNo}

                                                <div style={{
                                                    fontSize: "12px", display: item.paid ? "inline-block" : "none", float: "right", color: "white",
                                                    padding: "0px 7px", height: "25px",
                                                    backgroundColor: item.paid === "paid" ? "green" : "red",
                                                }}>{item.paid}  </div>
                                            </lable>

                                            <lable style={{ display: "block", fontSize: "12px" }}>Lco: {item.Lconame}

                                                <div style={{ fontSize: "12px", display: "inline-block", float: "right", color: "red" }}>({item.Lcocode})
 </div>
                                            </lable>
                                        </div>
                                    </li>

                                );
                            })}
                        </ul>

                        <Loader
                            visible={this.state.isShowStockLoader}
                            style={{ textAlign: "center", marginBottom: 10, }}
                            type="ThreeDots"
                            color="#00BFFF"
                            height="10"
                            width="20"
                        />
                    </div>

                </div>


                <MainContainer>
                    <div >

                        <div style={{
                            //      marginLeft: "70%", 
                            // postion: "relative",
                            // marginTop: 20 
                            marginLeft: "70%",
                            marginTop: "20px",

                        }}>
                            <Checkboxs
                                style={{ marginLeft: "89%" }}
                                textClassName="bouncechk__text"
                                className="bouncechk__customClassName "
                                text='CSV Operation'
                                modifierClass='green'
                                onChange={() => { this.toggleInCSV() }}
                                checked={this.state.isCSVOperation}
                                style={{ display: "inline", float: 'left', marginRight: 10 }}
                            />
                            <div style={{ display: "inline", float: 'left' }} >CSV Operation</div>
                            {this.isShowCSVOption()}
                        </div>
                    </div>
                    {this.showContain()}



                </MainContainer>
                <DialogBoxes
                    isShowLogOutWarning={this.state.isShowLogOutWarning}
                    logOut={this.logOut.bind(this)}
                    hindLogOutDialog={this.hindLogOutDialog.bind(this)}
                    hindLcoSearchDialog={this.hindLcoSearchDialog.bind(this)}
                    isShowLcoSearchDialog={this.state.isShowLcoSearchDialog}
                    lcoSearchResult={this.state.lcoSearchResult}
                    lcoSearchFunc={this.lcoSearchFunc.bind(this)}
                    selectedSTB={this.state.selectedSTB}
                    isShowProcessDialog={this.state.isShowProcessDialog}
                    hindProcessDialog={this.hindProcessDialog.bind(this)}
                    noOfDataGoingToProcess={this.state.noOfDataGoingToProcess}
                    noOfcompletedData={this.state.noOfcompletedData}
                    unProcessedData={this.state.unProcessedData}
                    transferFunc={this.transferFunc.bind(this)}
                    hindUnProcessedDataListDialog={this.hindUnProcessedDataListDialog.bind(this)}
                    isShowUnProcessedDataListDialog={this.state.isShowUnProcessedDataListDialog}
                    hindEmptyList={this.hindEmptyList.bind(this)}
                    isEmptyList={this.state.isEmptyList}
                    hindTransferWarningCSV={this.hindTransferWarningCSV.bind(this)}
                    isShowTransferWarningCSV={this.state.isShowTransferWarningCSV}
                    transferFuncCSV={this.transferFuncCSV.bind(this)}
                    noOfCSVRowsFinished={this.state.noOfCSVRowsFinished}
                    numberOfRowsInCsv={this.state.numberOfRowsInCsv}
                    csvErrorUpload={this.state.csvErrorUpload}
                    isShowCSVLoadDialog={this.state.isShowCSVLoadDialog}
                    hindCSVLoadDialog={this.hindCSVLoadDialog.bind(this)}
                    isShowUnProcessCSVList={this.state.isShowUnProcessCSVList}
                    hindUnProcessCSVList={this.hindUnProcessCSVList.bind(this)}

                />


                {this.logOutFunc()}

            </Rootcontainer>

        );
    }
}
