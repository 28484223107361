
import API from '../../Api';
import React, { Component } from 'react';
import logo from '../../assets/logo-3.png';
import loadinggif from '../../assets/loding-gif.gif';
import '../../screens/dashboard/index.css'
import { Card, Button, Col, Row, Container, InputGroup, FormControl, Dropdown } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import Drawer from 'react-drag-drawer';
import SelectSearch from 'react-select-search';
import Loader from 'react-loader-spinner';
import { MDBCol, MDBFormInline, MDBBtn, MDBNavbarBrand, MDBNavbarToggler, MDBNavbar, MDBCollapse, MDBNavbarNav } from
  "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";

import { Rootcontainer, RootDiv, RootTitle, RootBal, RootRight, RootLeft, RootValue } from './styled'
import {
  MdPersonPinCircle,
  MdPersonPin,
  MdLiveTv,
  MdSatellite,
  MdPowerSettingsNew,
  MdCast,
  MdBusinessCenter,
  MdSearch,
  MdNotInterested,
  MdInbox,
  MdAdd,
  MdNotifications

} from 'react-icons/md';
import DialogBoxs from './DialogBoxs';

import { IoMdCash, IoMdSearch, IoIosCheckmark, IoIosClose } from "react-icons/io";
import { FaUserAlt, FaRupeeSign, FaCircle, FaPlus } from 'react-icons/fa';
import { TiLocationOutline, TiLocation } from "react-icons/ti";
import { red, black } from 'ansi-colors';
import Undraw from 'react-undraw';
import GeneralValues from '../../GobelElements/Values';


export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloggedOut: false,
      lcoID: localStorage.getItem("userID"),
      lcoPassword: localStorage.getItem("userPassword"),
      domainName: localStorage.getItem("domain"),
      dashboardData: {},
      errorMessage: "",
      spinnerVisible: false,
      lcoBalance: "",
      lcocode: "",
      lcocity: "",
      lcoName: "",
      isShowLowBalance: false,
      searchText: "",
      isShowNodataDialog: false,
      isShowLogOutDialog: "",
      lcoList: JSON.parse(localStorage.getItem("lcolist")),
      kk: [],
      lco: localStorage.getItem("currentlco"),
      lcoSearchText: "",
      lcoSearchResult: [],
      isShowLcoList: false,
      modData: [],
      inStockListSearchText: "",
      inStockCurrentPage: 1,
      inStockDataList: [],
      totalCountInStock: 0,
      isShowStockLoader: false,
      selectedCustomer: {},
      isShowLcoSearch: localStorage.getItem("isShowStock"),
      isShowRechargeWarningDialog: false,
      isShowMantainanceDialog: false,
      maintIMG: "",
      maintText: "",
      advtURL: "",
      isShowAdvertisementDialog: false,
      advtDuration: 0,
      paygateway: ""


    };
    console.log("username", localStorage.getItem("userID"), localStorage.getItem("lcolist"));
    let arr = [];
    for (var i = 0; i < this.state.lcoList.length; i++) {
      arr.push({ "id": this.state.lcoList[i].lconame + " " + this.state.lcoList[i].lcocode, "value": this.state.lcoList[i].lcocode, "name": this.state.lcoList[i].lconame })
    }
    console.log("data in kk", arr);
    this.setState({ modData: arr });
    // this.state.kk = arr;
    this.state.modData = arr;
    console.log("data in kk", arr, this.state.modData);
    // this.getDashboardDetails();
    this.getLCO_Details();
    this.getADVT()


    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    // console.log("date in", date,arrayPackageDetails );
    console.log("loc list", JSON.parse(localStorage.getItem("lcolist")), this.state.kk);
  }
  componentDidMount() {
    this.setTheValue();
  }
  componentWillMount() {
    this.getDashboardDetails();
    this.checkLcoIsLow();
  }
  setTheValue() {

  }
  hindRechargeWarningDialog() {
    this.setState({ isShowRechargeWarningDialog: false })
  }
  checkLcoIsLow() {
    if (this.state.lcoBalance > 200) {
      this.setState({ isShowLowBalance: true });
    }
  }

  hindLogOutDialog() {
    this.setState({ isShowLogOutDialog: false });
  }
  hindNodataDialog() {
    this.setState({ isShowNodataDialog: false });
  }
  hindAdvertisementDialog() {
    debugger;
    this.setState({ isShowAdvertisementDialog: false })
  }
  checkToShowTheLcoList(text) {
    var len = text.length;
    console.log("length", len, this.state.modData[0].id);
    if (len) {
      var dummy = [];
      var length = this.state.lcoList.length;
      var searchText = text.toString().toLowerCase();
      console.log("data", length, len);
      for (var i = 0; i < length; i++) {

        var val = ""
        val = this.state.modData[i].id.toString().toLowerCase();
        // var lower = val.toString();
        // console.log("lower",val,searchText);

        var n = val.search(searchText);
        if (n != -1) {
          dummy.push(this.state.modData[i]);
        }
      }
      console.log("dummy", dummy);
      this.setState({ isShowLcoList: true, lcoSearchResult: dummy });
    } else {
      this.setState({ isShowLcoList: false });
    }
  }
  getADVT() {



    const { lcoID, lcoPassword, domainName, searchText } = this.state;
    console.log("vl", lcoID, lcoPassword, domainName);
    debugger;
    API.getAdvt(lcoID, lcoPassword, domainName)
      .then((response) => response.json())
      .then((responsejson) => {
        console.log("responser from advt", responsejson);
        debugger;
        if (responsejson.url) {
          this.state.advtURL = responsejson.url
          this.setState({ advtURL: responsejson.url, isShowAdvertisementDialog: true, advtDuration: parseInt(responsejson.duration) });

        }
        let myInterval = setInterval(() => {
          this.setState({ advtDuration: this.state.advtDuration - 1 })
          if (this.state.advtDuration == 0) {
            clearInterval(myInterval);
            this.setState({ isShowCancelBtn: true })
          }
        }, 1000)


      }).catch((error) => {
        console.log("Error in advt", error);
        debugger;
      });

  }
  getSTBDetails(item) {
    console.log("item", item);
    if (item.Customer) {
      this.setState({ spinnerVisible: true });
      const { lcoID, lcoPassword, domainName, searchText } = this.state;
      API.STBSearch(lcoID, lcoPassword, "stbsearch", item.SNo, domainName)
        .then((response) => response.json())
        .then((responsejson) => {
          console.log("response", responsejson);
          debugger;
          if (responsejson.message == "no data") {
            this.setState({ spinnerVisible: false });
          } else {
            localStorage.setItem("currentlco", item.Lcocode);
            this.setState({ spinnerVisible: false });
            this.props.moveToCustomerDetails("customerdetails", responsejson, this.state.searchText);
          }
        });
    }
    else {
      this.setState({ isShowNodataDialog: true, selectedCustomer: item })
    }

  }
  moveTOUnmappedStbCreationFormScreen() {

    console.log("this.state.selectedCustomer.SNo", this.state.selectedCustomer.SNo, this.state.selectedCustomer);
    debugger;
    this.props.moveToCreateCustomerForm(this.state.selectedCustomer.SNo)
  }

  // componentWillReceiveProps(){
  //   const searchQuery = this.props.searchQuery;
  //   if(searchQuery){
  //     this.state({})
  //   }
  // }
  getLCO_Details() {
    // const { lcoID, lcoPassword, domainName } = this.state;
    const { lcoID, lcoPassword, domainName } = this.state;

    API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
      .then((response) => response.json())
      .then((responsejson) => {

        if (responsejson.message == "error user not authenticated") {
          this.logOut()
        } else {
          this.setState({
            lcoName: responsejson.lconame, lcoBalance: responsejson.balance,
            lcocode: responsejson.lcocode,
            kk: [{ "name": "mani" }],
            paygateway: responsejson.paygateway
          })
          // var arr=[];
          // for(var i =0;i<this.state.lcoList.length;i++){
          //    arr.push({"name":this.state.lcoList[i].lconame,"value":this.state.lcoList[i].lcocode})
          // }
          // console.log("data in kk",arr);
          // // this.setState({kk:arr});
          // this.state.kk =arr;
          // console.log("data in kk",arr,this.state.kk);
        }

      });
  }


  getDashboardDetails() {
    this.setState({ spinnerVisible: true });
    const { lcoID, lcoPassword, domainName } = this.state;
    console.log("data", lcoID, lcoPassword, domainName);
    API.dashboardAPI(lcoID, lcoPassword, "dashboard", domainName)
      .then((response) => response.json())
      .then((responsejson) => {
        console.log("responser", responsejson);
        debugger;



        this.setState({
          dashboardData: responsejson, spinnerVisible: false, errorMessage: "",
          isShowMantainanceDialog: responsejson.maint,
          maintText: responsejson.mainttext,
          maintIMG: responsejson.maintimage

        });
        this.getLCO_Details();

        console.log("DashBoard", this.state.dashboardData);


      })
      .catch((err) => {
        console.log("error", err);
        this.setState({ errorMessage: "Unable to load !", spinnerVisible: false });
      });
  }

  logOutFunc() {
    if (this.state.isloggedOut) {
      return <Redirect to="/login" />
    } else {
      return null;
    }
  }
  logOut() {

    localStorage.removeItem("userID");
    const username = localStorage.getItem("userID");
    if (!username) {
      this.setState({ isloggedOut: true });
    }
  }
  onSelectLco(text) {
    localStorage.setItem("currentlco", text);
    this.getLCO_Details();
    this.getDashboardDetails();
    this.setState({ isShowLcoList: false, lcoSearchResult: [], lcoSearchText: "" })
  }

  nextPageData(data, pageNo) {
    // debugger;
    let lengthOfACustomerList = this.state.inStockDataList.length;
    console.log("lenght of the list", this.state.totalCountInStock);
    if (lengthOfACustomerList < this.state.totalCountInStock) {
      // console.log("pageCount", pageNo);
      // this.setState({ animating: true });
      this.setState({ isShowStockLoader: true })
      const { lcoID, lcoPassword, domainName, inStockListSearchText } = this.state;
      API.stockListSearch(lcoID, lcoPassword, "liststock", inStockListSearchText, pageNo, domainName)

        .then((response) => response.json())
        .then((responsejson) => {
          if (responsejson.count) {
            console.log("SearchResponse", responsejson);
            let oldData = data;
            let newData = oldData.concat(responsejson.content);
            let count = this.state.inStockCurrentPage + 1;

            this.setState({ inStockDataList: newData, inStockCurrentPage: count, isShowStockLoader: false });
            // console.log("data", this.state.customerList);
            // if (newData.length < responsejson.count) {

            //     this.nextPageData(this.state.customerList, (this.state.pageCount + 1));
            // }
          }
        })
        .catch((err) => {

          console.log("error", err);
          // this.setState({ isSpinnerVisible: false });
          // break


        });
    }
  }

  stockListSearch(text) {

    const { lcoID, lcoPassword, domainName, inStockListSearchText } = this.state;
    if (text.length > 2) {
      this.setState({ inStockDataList: [], isShowInStockList: true, isShowStockLoader: true, noData: "" });

      API.stockListSearch(lcoID, lcoPassword, "liststock", text, 1, domainName)
        .then((response) => response.json())
        .then((responseJson) => {
          console.log("response", responseJson);
          if (responseJson.count) {


            this.setState({ inStockDataList: responseJson.content, isShowInStockList: true, inStockCurrentPage: 1, totalCountInStock: responseJson.count, isShowStockLoader: false });
            console.log("In stock list", this.state.inStockDataList);
          } else {
            this.setState({ inStockDataList: [], isShowStockLoader: false, noData: "No data found!" });
          }
        });
    } else {
      this.setState({ isShowInStockList: false, isShowInStockList: false, isShowStockLoader: false, noData: "" });
    }
  }
  handleScroll(e) {
    // console.log("scorller",e.target.scrollHeight,e.target.scrollTop,e.target.clientHeight);
    const bottom = e.target.scrollHeight - (e.target.scrollTop) === e.target.clientHeight;
    // console.log("lco",bottom);
    if (bottom) {

      this.nextPageData(this.state.inStockDataList, (this.state.inStockCurrentPage + 1));
    }
  }

  isShowLcoSearchFunc() {
    if (this.state.isShowLcoSearch != "false") {
      return (
        <input type="text" text="Search" placeholder="Lco Search....              "
          onChange={(e) => {

            this.setState({ lcoSearchText: e.target.value })
            this.checkToShowTheLcoList(e.target.value);
          }}
          // onKeyPress={(e) => {

          //   // if (e.keyCode == 13 || e.which == 13) {
          //   //   this.getSTBDetails();
          //   // }
          // }}
          value={this.state.lcoSearchText}
          className="input-STB"
          style={{ backgroundColor: "transparent", borderColor: "transparent", borderBottom: "2px solid white", color: "white", textAlign: "center", fontSize: 15, borderRight: "2px solid white", fontWeight: "bold", marginRight: 15 }} />
      );
    } else {
      return null;
    }
  }

  render() {


    return (
      //   <LoadingOverlay
      //   className="spinner"
      //   overlay={true}
      //   active={this.state.spinnerVisible}
      //   spinner={<BounceLoader className="spinnerContent" color="green" />}

      // >

      <Rootcontainer>

        <DialogBoxs
          isShowNodataDialog={this.state.isShowNodataDialog}
          hindNodataDialog={this.hindNodataDialog.bind(this)}
          isShowLogOutDialog={this.state.isShowLogOutDialog}
          hindLogOutDialog={this.hindLogOutDialog.bind(this)}
          logOut={this.logOut.bind(this)}
          moveToCreateCustomerForm={this.props.moveToCreateCustomerForm.bind(this)}
          selectedCustomer={this.state.selectedCustomer}
          moveTOUnmappedStbCreationFormScreen={this.moveTOUnmappedStbCreationFormScreen.bind(this)}
          isShowRechargeWarningDialog={this.state.isShowRechargeWarningDialog}
          hindRechargeWarningDialog={this.hindRechargeWarningDialog.bind(this)}
          lcoID={this.state.lcoID}
          lcocode={this.state.lcocode}
          isShowMantainanceDialog={this.state.isShowMantainanceDialog}
          maintIMG={this.state.maintIMG}
          maintText={this.state.maintText}
          getDashboardDetails={this.getDashboardDetails.bind(this)}
          advtURL={this.state.advtURL}
          isShowAdvertisementDialog={false}
          advtDuration={this.state.advtDuration}
          hindAdvertisementDialog={this.hindAdvertisementDialog.bind(this)}
          paygateway={this.state.paygateway}


        />
        <LoadingOverlay

          className={this.state.spinnerVisible ? "displaySpinner" : ""}
          overlay={true}
          active={this.state.spinnerVisible}
          spinner={<img src={loadinggif} className="spinnerContent" color="green" />}

        ></LoadingOverlay>

        <Drawer

          open={this.state.isShowLogOutDialog}
          onRequestClose={this.toggle}
          direction='left'
        >

          <Card >


            <Card.Body className="pl-3 pr-3">
              <div style={{ fontSize: 15, fontWeight: 'bold', textAlign: "center" }}>Do you want <span style={{ fontSize: "1.5em", color: "red" }}>Log out</span> ?</div>

              <Undraw className="undrawimg" name="monitor" />

              <Button
                style={{ marginLeft: 20 }}
                className="success" className="mt-3 pull-right"
                onClick={() => {


                  this.setState({ isShowLogOutDialog: false })

                }}
              >Cancel</Button>

              <Button
                style={{ backgroundColor: "red" }}
                className="danger" className="mt-3 pull-right"
                onClick={() => {

                  // this.props.hindNodataDialog();
                  this.logOut();

                }}
              >OK</Button>



            </Card.Body>
          </Card>

        </Drawer>

        <Drawer

          open={this.state.isShowLowBalance}
          onRequestClose={this.toggle}
          direction='left'

        >
          {/* <div>Hey Im inside a drawer!</div> */}
          <Card >

            <Card.Body>
              <div style={{ fontSize: 20, fontWeight: 'bold' }}> Low Balance !</div>

              <button
                onClick={() => this.setState({ isShowLowBalance: false })}
                style={{ background: "red", color: 'white', padding: 15, marginTop: 20, textAlign: "right", display: "block", width: 60, height: 60, marginLeft: 256, borderRadius: 20 }}>OK</button>

            </Card.Body>
          </Card>

        </Drawer>



        <div className="header-div" style={{ backgroundColor: GeneralValues.blueColor }}>
          <h3 style={{ marginLeft: '80px' }}>Dashboard</h3>


          <img src={GeneralValues.circlelogo} style={{ float: "left", marginLeft: " -120px" }} className="logo-img" alt="logo" />

          <MdPowerSettingsNew onClick={() => { this.setState({ isShowLogOutDialog: true }) }} value="Logout" className="fontSize login" />



          <div style={{ width: 130, height: 30, display: "flex", marginLeft: "73%", marginTop: 10, justifyContent: "flex-end" }}>
            {/* <Dropdown  style={{marginRight:"45vw",backgroundColor:"transparent"}}>
         <Dropdown.Toggle style={{backgroundColor:"transparent",borderColor:"white",borderWidth: 3,}}  id="dropdown-basic" >
    lco list
  </Dropdown.Toggle>
         <Dropdown.Menu style={{overflow:"auto",maxHeight:"40vh"}}>
           {this.state.lcoList.map((item,i)=>{
             return(
              <Dropdown.Item onClick={()=>{this.onSelectLco(item.lcocode)}}>
              {item.lconame}
              </Dropdown.Item>
             );
           })}
           </Dropdown.Menu>
           
         </Dropdown> */}
            {this.isShowLcoSearchFunc()}
            <div
              onScrollCapture={(e) => { console.log("e", e) }}
              style={{
                position: "absolute",
                display: this.state.isShowLcoList ? "block" : "none",
                listStyle: "none",
                backgroundColor: "white",
                padding: 0,
                boxShadow: "0px 1px 3px grey",
                zIndex: 9,
                width: "20vw",
                top: 50,
                overflow: "auto",
                maxHeight: "30vh",
                marginRight: "370px"
                // marginLeft: "-300px !important"
              }}
            //  onScroll={(e)=>console.log("scrolling",e)}
            >
              <label style={{ fontSize: 15, fontWeight: "bold", color: "rgb(26, 172, 195)", marginLeft: 10 }}>Lco Lis:</label>
              <ul

                style={{ listStyle: "none", padding: 10 }}>
                {this.state.lcoSearchResult.map((item, i) => {

                  return (
                    <li
                      onScroll={(e) => console.log("scrolling", e)}
                      onClick={() => { this.onSelectLco(item.value) }}
                      style={{ fontSize: 15, color: "black", lineHeight: 2, borderBottom: "1px solid rgba(26, 172, 195,0.5)", cursor: "pointer" }}>
                      {item.name} <span style={{ fontSize: 12, color: "#d61818", paddingLeft: 1 }}
                      >({item.value})</span></li>
                  );
                })}
              </ul>

            </div>



            <input type="text" text="Search" placeholder="Global Search..."
              onChange={(e) => {
                this.setState({ inStockListSearchText: e.target.value })
                this.stockListSearch(e.target.value);
              }}

              value={this.state.inStockListSearchText}
              className="input-STB"
              style={{ backgroundColor: "transparent", borderColor: "transparent", borderBottom: "2px solid white", color: "white", textAlign: "center", fontSize: 15, borderRight: "2px solid white", fontWeight: "bold" }} />
            {/* {this.isShowInStockList()} */}
            <div style={{
              position: "absolute",
              display: this.state.isShowInStockList ? "block" : "none",
              listStyle: "none",
              backgroundColor: "white",
              padding: 0,
              boxShadow: "0px 1px 3px grey",
              zIndex: 9,
              width: "22vw",
              top: 50,
              overflow: "auto",
              maxHeight: "30vh",

            }}
              onScroll={(e) => this.handleScroll(e)}>
              <label style={{ fontSize: 15, fontWeight: "bold", color: "rgb(26, 172, 195)", marginLeft: 10 }}>List:</label>
              <div style={{ textAlign: "center", color: "gray" }}>{this.state.noData}</div>
              <ul style={{ listStyle: "none", padding: 10 }}>

                {this.state.inStockDataList.map((item, i) => {

                  return (


                    <li className="search-list"
                      onClick={() => {
                        // if (item.Customer) {
                        this.getSTBDetails(item)
                        // } else {
                        //   this.moveTOUnmappedStbCreationFormScreen(item)
                        // }

                      }}
                      style={{ fontSize: 15, color: "black", lineHeight: 2, borderBottom: "1px solid rgba(26, 172, 195,0.5)", cursor: "pointer" }}>
                      <div>
                        <lable style={{ display: "block" }}>
                          Name:
   {item.Customer ? item.Customer : <FaPlus style={{
                            background: " #0a3f65",
                            color: "white", width: "20px", height: "20px", padding: "2px", borderRadius: "10px", marginLeft: "8px"
                          }} />}

                          <FaCircle style={{
                            display: item.status ? "inline-block" : "none",
                            height: "12px", float: "right", marginTop: "8px",
                            color: item.status == "Live" ? "green" : "red",
                          }} />


                        </lable>

                        <lable style={{ display: "block" }}>STB: {item.SNo}

                          <div style={{
                            fontSize: "12px", display: item.paid ? "inline-block" : "none", float: "right", color: "white",
                            padding: "0px 7px", height: "25px",
                            backgroundColor: item.paid === "paid" ? "green" : "red",
                          }}>{item.paid}  </div>
                        </lable>

                        <lable style={{ display: "block", fontSize: "12px" }}>Lco: {item.Lconame}

                          <div style={{ fontSize: "12px", display: "inline-block", float: "right", color: "red" }}>({item.Lcocode})
  </div>
                        </lable>
                      </div>
                    </li>

                  );
                })}
              </ul>

              <Loader
                visible={this.state.isShowStockLoader}
                style={{ textAlign: "center", marginBottom: 10, }}
                type="ThreeDots"
                color="#00BFFF"
                height="10"
                width="20"
              />
            </div>

            <button className="name" onClick={() => {
              if (this.state.inStockListSearchText) {
                this.props.moveToSearchWithQuery(this.state.inStockListSearchText)
              }
            }}>
              <IoMdSearch className="search" />
            </button>
            <button className="name" style={{ width: 100, background: "linear-gradient(to right, rgb(32, 28, 71), rgb(43, 40, 83), rgb(48, 43, 99))" }} onClick={() => {
              this.setState({ isShowRechargeWarningDialog: true })
            }}>
              Topup
            </button>
            <button className="name" style={{ width: 100, background: "linear-gradient(to right, rgb(255, 65, 108), rgb(255, 75, 43))", borderRadius: 150 / 2 }} onClick={() => {
              this.props.onClickNotifications()
            }}>
              <MdNotifications style={{ width: 20, height: 20 }} />
            </button>
          </div>


        </div>



        <label className="errorBlock">{this.state.errorMessage}</label>
        <RootDiv>
          <RootLeft>
            <RootTitle>
              Name :
                    </RootTitle>

            <RootValue
              style={{ cursor: "pointer" }}
              onClick={() => { this.props.onClickProfile() }} >
              {this.state.lcoName}

            </RootValue>
            <div className="lcocode" >

              (<span style={{ color: "black" }}>Lco code:</span> {this.state.lcocode})
                </div>
          </RootLeft>

          <RootRight>
            <RootTitle>
              Balance :
</RootTitle>

            <RootValue>
              {'\u20B9'} {this.state.lcoBalance}
              {/* <Button
              onClick={()=>{
                this.setState({isShowRechargeWarningDialog:true})
              }}
              style={{backgroundColor:"green",borderColor:"green",marginLeft:15,fontSize:12,fontWeight:"bold"}}
              >Top Up</Button> */}
              {/* <MdAdd 
              title="Top up"
              onClick={()=>{this.setState({isShowRechargeWarningDialog:true})}}
              style={{backgroundColor:"green",color:"white",t}}/> */}

            </RootValue>

          </RootRight>
        </RootDiv>

        {/* <Row style={{ padding: 11, marginRight: 0 }} className="dashboard-card">
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => this.props.moveToSearchWithQuery("MappedCustomer")}
            lg={3} md={4} sm={6} xs={12} className="mt-2 mb-2">
            <Card text="white" className="" style={{ backgroundImage: "linear-gradient(to right bottom, rgb(0, 145, 234), rgb(202, 231, 249))", color: "#ffffff" }}>

              <Card.Body>
                <Card.Title>{this.state.dashboardData.mappedcustomer}</Card.Title>
                <Card.Text>
                  Mapped Customers
    </Card.Text>
                <MdPersonPinCircle className="widget-icons" />
              </Card.Body>
            </Card>
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            lg={3} md={4} sm={6} xs={12} className="mt-2 mb-2" onClick={() => this.props.moveToSearchWithQuery("UnmappedCustomer")}>
            <Card text="white" className="" style={{ backgroundImage: "linear-gradient(to bottom right,rgb(136, 183, 138),rgb(184, 229, 186))" }}>

              <Card.Body>
                <Card.Title>{this.state.dashboardData.unmappedcustomer}</Card.Title>
                <Card.Text>
                  Unmapped Customers
  </Card.Text>
                <MdPersonPinCircle className="widget-icons" />
              </Card.Body>
            </Card>
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            lg={3} md={4} sm={6} xs={12} className="mt-2 mb-2" onClick={() => this.props.moveToSearchWithQuery("Unpaid Live")}>
            <Card text="white" className="" style={{ backgroundImage: "linear-gradient(to right bottom, rgb(196, 134, 110), rgb(231, 170, 147))" }}>

              <Card.Body>
                <Card.Title>{this.state.dashboardData.unpaid}</Card.Title>
                <Card.Text>
                  Unpaid Customers
  </Card.Text>
                <MdPersonPin className="widget-icons" />
              </Card.Body>
            </Card>
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            lg={3} md={4} sm={6} xs={12} className="mt-2 mb-2" onClick={() => this.props.moveToSearchWithQuery("Paid Live")}>
            <Card text="white" className="" style={{ backgroundImage: "linear-gradient(to right bottom, rgb(156, 39, 176), rgb(208, 133, 220))" }}>

              <Card.Body>
                <Card.Title>{this.state.dashboardData.paid}</Card.Title>
                <Card.Text>
                  Paid Customers
  </Card.Text>
                <MdPersonPin className="widget-icons" />
              </Card.Body>
            </Card>
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            lg={3} md={4} sm={6} xs={12} className="mt-2 mb-2" onClick={() => this.props.moveToSearchWithQuery("Live Customer")} >
            <Card text="white" className="" style={{ backgroundImage: "linear-gradient(to right bottom, rgb(103, 58, 183), rgb(153, 110, 229))" }}>

              <Card.Body>
                <Card.Title>{this.state.dashboardData.livecustomer}</Card.Title>
                <Card.Text>
                  Live Customers
  </Card.Text>
                <MdLiveTv className="widget-icons" />
              </Card.Body>
            </Card>
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            lg={3} md={4} sm={6} xs={12} className="mt-2 mb-2" onClick={() => this.props.moveToSearchWithQuery("Disconnected Customer")}>
            <Card text="white" className="" style={{ backgroundImage: "linear-gradient(to right bottom, rgb(222, 39, 216), rgb(237, 179, 235))" }}>

              <Card.Body>
                <Card.Title>{this.state.dashboardData.disconnectedcustomer}</Card.Title>
                <Card.Text>
                  Disconnected Customers
  </Card.Text>
                <MdSatellite className="widget-icons" />
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} md={4} sm={6} xs={12} className="mt-2 mb-2">
            <Card text="white" className="no-link" style={{ backgroundImage: "linear-gradient(to right bottom, rgb(33, 150, 243),rgb(102, 173, 229))" }}>

              <Card.Body>
                <Card.Title>{this.state.dashboardData.livestb}</Card.Title>
                <Card.Text>
                  Live STB
  </Card.Text>
                <MdLiveTv className="widget-icons" />
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} md={4} sm={6} xs={12} className="mt-2 mb-2">
            <Card text="white" className="no-link" style={{ backgroundImage: "linear-gradient(to bottom right, rgb(3, 152, 18), rgb(61, 214, 76))" }}>

              <Card.Body>
                <Card.Title>{this.state.dashboardData.disconnectedstb}</Card.Title>
                <Card.Text>
                  Disconnected STB
  </Card.Text>
                <MdSatellite className="widget-icons" />
              </Card.Body>
            </Card>
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            lg={3} md={4} sm={6} xs={12} className="mt-2 mb-2" onClick={() => this.props.moveToSearchWithQuery("Live")}>
            <Card text="white" className="" style={{ backgroundImage: "linear-gradient(to bottom right, rgb(0, 188, 212), rgb(88, 218, 235))" }}>

              <Card.Body>
                <Card.Title>{this.state.dashboardData.totalcustomer}</Card.Title>
                <Card.Text>
                  Total Customers
  </Card.Text>
                <MdPersonPinCircle className="widget-icons" />
              </Card.Body>
            </Card>
          </Col>


          <Col lg={3} md={4} sm={6} xs={12} className="mt-2 mb-2">
            <Card text="white" className="no-link" style={{ backgroundImage: "linear-gradient(to bottom right, rgb(225, 173, 17), rgb(255, 219, 109))" }}>

              <Card.Body>
                <Card.Title>{this.state.dashboardData.collectionsthismonth ? this.state.dashboardData.collectionsthismonth:0 }</Card.Title>
                <Card.Text>
                  Paid Amount Current Month
  </Card.Text>
                <label className="widget-icons" style={{ top: 5 }}>
                  {'\u20B9'}
                </label>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} md={4} sm={6} xs={12} className="mt-2 mb-2">
            <Card text="white" className="no-link" style={{ backgroundImage: "linear-gradient(to bottom right, #AA3939, #FFAAAA" }}>

              <Card.Body>
                <Card.Title>{this.state.dashboardData.unpaidamount === "null" ?  0:this.state.dashboardData.unpaidamount}</Card.Title>

                <Card.Text>
                  Unpaid Amount Current Month
  </Card.Text>
                <label className="widget-icons" style={{ top: 5 }}>
                  {'\u20B9'}
                </label>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={3} md={4} sm={6} xs={12} className="mt-2 mb-2">
            <Card text="white" className="no-link" style={{ backgroundImage: "linear-gradient(to bottom right, #AA7B39, #FFDCAA" }}>

              <Card.Body>
                <Card.Title>{this.state.dashboardData.instock}</Card.Title>

                <Card.Text>
                  Instock
  </Card.Text>
                <MdBusinessCenter className="widget-icons" />
              </Card.Body>
            </Card>
          </Col>

          <Col lg={3} md={4} sm={6} xs={12} className="mt-2 mb-2">
            <Card text="white" className="no-link" style={{ backgroundImage: "linear-gradient(to bottom right, #2E4172, #7887AB" }}>

              <Card.Body>
                <Card.Title>{this.state.dashboardData.totalstb}</Card.Title>

                <Card.Text>
                  Total STB
  </Card.Text>
                <MdCast className="widget-icons" />
              </Card.Body>
            </Card>
          </Col>
        </Row> */}


        {/* first Row */}
        <div className="margin-center" >
          <Row className="dashboard" style={{ marginLeft: "3%", marginTop: 40 }}>

            <Col style={{ cursor: "pointer" }} onClick={() => this.props.moveToSearchWithQuery("Live Customer")} md={2} sm={6} xs={12} className="my-4">
              <Card style={{
                width: '12rem', textAlign: "center",
                //background: "#F7F7F7",
                // background:" #00BCD4",
                // color: " #fff",
                boxShadow: "0.5px 0.5px 2px 2px #958f88",
              }}>
                <div className="circle">
                  <MdLiveTv className="text-center" style={{ background: GeneralValues.blueColor }}> </MdLiveTv>
                </div>

                <Card.Body style={{ marginTop: "-15px", padding: " 1.3rem" }}>
                  <Card.Title style={{ color: GeneralValues.mustard }}>{this.state.dashboardData.livestb} </Card.Title>
                  <Card.Text style={{ color: GeneralValues.garland, fontSize: 13, fontWeight: "bold" }}>
                    Active STB
    </Card.Text>

                </Card.Body>
              </Card>
            </Col>
            <Col style={{ cursor: "pointer" }} onClick={() => this.props.moveToSearchWithQuery("MappedCustomer")} md={3} sm={6} xs={12} className="my-4">
              <Card style={{
                width: '12rem', textAlign: "center",
                // background: "linear-gradient(to right, #36d1dc, #5b86e5)",
                //  background:" #8BC34A ",
                // color: " #fff",
                boxShadow: "0.5px 0.5px 2px 2px #958f88",
              }}>
                <div className="circle">
                  <TiLocation className="text-center" style={{ background: GeneralValues.blueColor }} ></TiLocation>
                </div>

                <Card.Body style={{ marginTop: "-15px", padding: "1rem 0" }}>
                  <Card.Title style={{ color: GeneralValues.mustard }}>{this.state.dashboardData.mappedcustomer} </Card.Title>
                  <Card.Text style={{ color: GeneralValues.garland, fontSize: 13, fontWeight: "bold" }}>
                    Assigned Customers
    </Card.Text>

                </Card.Body>
              </Card>
            </Col>

            <Col style={{ cursor: "pointer" }}
              onClick={() => { this.props.moveToUnPaid("today") }}
              //onClick={() => this.props.moveToSearchWithQuery("MappedCustomer")} 
              md={3} sm={6} xs={12} className="my-4">
              <Card style={{
                width: '12rem', textAlign: "center",
                // background: "linear-gradient(to right, #36d1dc, #5b86e5)",
                //  background:" #8BC34A ",
                // color: " #fff",
                boxShadow: "0.5px 0.5px 2px 2px #958f88",

              }}>
                <div className="circle">
                  <IoIosClose className="text-center" style={{ background: GeneralValues.blueColor }} ></IoIosClose>
                </div>

                <Card.Body style={{ marginTop: "-15px", padding: "1rem 0" }}>
                  <Card.Title style={{ color: GeneralValues.mustard }}>{this.state.dashboardData.todaydis} </Card.Title>
                  <Card.Text style={{ color: GeneralValues.garland, fontSize: 13, fontWeight: "bold" }}>
                    Disconnections - Today
    </Card.Text>

                </Card.Body>
              </Card>
            </Col>


            <Col
              style={{ cursor: "pointer" }}
              onClick={() => { this.props.moveToAllSTB() }}
              md={2} sm={6} xs={12} className="my-4">
              <Card style={{
                width: '12rem', textAlign: "center",
                // background:" #607D8B",
                boxShadow: "0.5px 0.5px 2px 2px #958f88",
              }}>
                <div className="circle">
                  <MdInbox className="text-center" style={{ background: GeneralValues.blueColor }}
                  ></MdInbox>
                </div>

                <Card.Body style={{ marginTop: "-15px" }}>
                  <Card.Title style={{ color: GeneralValues.mustard }}>{this.state.dashboardData.totalstb} </Card.Title>
                  <Card.Text style={{ color: GeneralValues.garland, fontSize: 13, fontWeight: "bold" }}>
                    Total STB
    </Card.Text>

                </Card.Body>
              </Card>
            </Col>
            <Col md={2} sm={6} xs={12} className="my-4" style={{ cursor: "pointer" }} onClick={() => this.props.moveToSearchWithQuery("Unpaid Live")}>
              <Card style={{
                width: '12rem', textAlign: "center",
                //  background:" #E91E63",
                boxShadow: "0.5px 0.5px 2px 2px #958f88",
              }}>
                <div className="circle">
                  <IoIosClose className="text-center" style={{ background: GeneralValues.blueColor }}></IoIosClose>
                </div>

                <Card.Body style={{ marginTop: "-15px", padding: "1rem 0" }}>
                  <Card.Title style={{ color: GeneralValues.mustard }}>{this.state.dashboardData.unpaid}</Card.Title>
                  <Card.Text style={{ color: GeneralValues.garland, fontSize: 13, fontWeight: "bold" }}>
                    Unpaid customer
    </Card.Text>

                </Card.Body>
              </Card>
            </Col>

            <Col md={2} sm={6} xs={12} className="my-4" style={{ cursor: "pointer" }} onClick={() => this.props.moveToSearchWithQuery("Disconnected")}>
              <Card style={{
                width: '12rem', textAlign: "center",
                // background:" #4CAF50",
                boxShadow: "0.5px 0.5px 2px 2px #958f88",
              }}>
                <div className="circle">
                  <  MdNotInterested className="text-center" style={{ background: GeneralValues.blueColor }}></MdNotInterested>
                </div>

                <Card.Body style={{ marginTop: "-15px", padding: " 1.4rem" }}>
                  <Card.Title style={{ color: GeneralValues.mustard }}>{this.state.dashboardData.disconnectedstb} </Card.Title>
                  <Card.Text style={{ color: GeneralValues.garland, fontSize: 13, fontWeight: "bold" }}>
                    Deactive STB
    </Card.Text>

                </Card.Body>
              </Card>
            </Col>



            {/* <Col md={3} sm={6} xs={12} className="my-4" style={{ cursor: "pointer" }} onClick={() => this.props.moveToSearchWithQuery("Live Customer")}>
              <Card style={{
                width: '12rem', textAlign: "center", background: " linear-gradient(to right, #11998e, #38ef7d)",
                color: " #fff"
              }}>
                <div className="circle">
                  <MdLiveTv className="text-center" style={{ background: "linear-gradient(to right, rgb(28, 178, 137), rgb(45, 213, 131))" }}> </MdLiveTv>
                </div>

                <Card.Body style={{ marginTop: "-15px", padding: "1rem 0" }}>
                  <Card.Title>{this.state.dashboardData.livecustomer} </Card.Title>
                  <Card.Text>
                    live customer
    </Card.Text>

                </Card.Body>
              </Card>
            </Col>
            <Col md={3} sm={6} xs={12} style={{ cursor: "pointer" }} className="my-4" onClick={() => this.props.moveToSearchWithQuery("Disconnected Customer")}>
              <Card style={{
                width: '12rem', textAlign: "center", background: "linear-gradient(to right, #ff512f, #dd2476)",
                //  background:" #3F51B5",
                color: " #fff"
              }}>
                <div className="circle">
                  <  MdNotInterested className="text-center" style={{ background: "linear-gradient(to right, #ff512f, #dd2476)" }}></MdNotInterested>
                </div>
                <Card.Body style={{ marginTop: "-15px", marginBottom: "0px" }}>

                  <Card.Title>{this.state.dashboardData.disconnectedcustomer}  </Card.Title>
                  <Card.Text>
                    Disconnected customer
    </Card.Text>

                </Card.Body>
              </Card>
            </Col> */}




            {/* <Col  md={3} sm={6} xs={12} className="my-4">
<Card style={{ width: '12rem',textAlign: "center",background:" linear-gradient(to right, #ff4e50, #f9d423)",
//  background:" #FF5722",
    color:" #fff" }}>
   <div className="circle">
   <FaRupeeSign className="text-center" style={{background: "linear-gradient(to right, rgb(253, 114, 68), rgb(251, 177, 47))"}}></FaRupeeSign>
   </div>
    
  <Card.Body  style={{marginTop: "-15px" , marginBottom: "0px"}}>
    <Card.Title>{this.state.dashboardData.collectionsthismonth ? this.state.dashboardData.collectionsthismonth:0 } </Card.Title>
    <Card.Text>
    Paid Amount Current Month
    </Card.Text>
    
  </Card.Body>
</Card>
  </Col> */}

            {/* <Col  md={3} sm={6} xs={12} className="my-4">
<Card style={{ width: '12rem',textAlign: "center", background: "linear-gradient(to right, #16222a, #3a6073)",
// background:" #795548",
    color:" #fff" }}>
   <div className="circle">
   <FaRupeeSign className="text-center" style={{background: "linear-gradient(to right, rgb(31, 50, 61), rgb(49, 80, 96))"}}></FaRupeeSign>
   </div>
  <Card.Body  style={{marginTop: "-15px" , marginBottom: "0px"}}>
    <Card.Title>{this.state.dashboardData.unpaidamount === "null" ?  0:this.state.dashboardData.unpaidamount} </Card.Title>
    <Card.Text>
    Unpaid Amount Current Month
    </Card.Text>
    
  </Card.Body>
</Card>
  </Col> */}




            {/* <Col md={2} sm={6} xs={12} className="my-4" style={{ cursor: "pointer" }} onClick={() => this.props.moveToSearchWithQuery("Paid Live")}>
              <Card style={{
                width: '12rem', textAlign: "center", background: "linear-gradient(to right, #4e54c8, #8f94fb)",
                 
              }}>
                <div className="circle">
                
                  <IoIosCheckmark className="text-center" style={{ background: "linear-gradient(to right, rgb(93, 99, 212), rgb(128, 133, 239))", width: " 85px" }}></IoIosCheckmark>
                </div>

                <Card.Body style={{ marginTop: "-15px", padding: "1rem 0" }}>
                  <Card.Title>{this.state.dashboardData.paid} </Card.Title>

                  <Card.Text style={{ color: "#fff" }}>
                    paid customer
    </Card.Text>

                </Card.Body>
              </Card>
            </Col> */}

            <Col md={2} sm={6} xs={12} className="my-4" style={{ cursor: "pointer" }} onClick={() => this.props.moveToSearchWithQuery("UnmappedCustomer")} >
              <Card style={{ width: '12rem', textAlign: "center", boxShadow: "0.5px 0.5px 2px 2px #958f88" }}>
                <div className="circle">
                  <TiLocationOutline className="text-center" style={{ background: GeneralValues.blueColor }}></TiLocationOutline>
                </div>

                <Card.Body style={{ marginTop: "-15px", padding: "1rem 0" }}>
                  <Card.Title style={{ color: GeneralValues.mustard }}>{this.state.dashboardData.unmappedcustomer}  </Card.Title>
                  <Card.Text style={{ color: GeneralValues.garland, fontSize: 13, fontWeight: "bold" }}>
                    Unassigned Customers
    </Card.Text>

                </Card.Body>
              </Card>
            </Col>




            <Col md={2} sm={6} xs={12} className="my-4" style={{ cursor: "pointer" }} onClick={() => { this.props.moveToUnPaid("tomorrow") }}>
              <Card style={{
                width: '12rem', textAlign: "center", boxShadow: "0.5px 0.5px 2px 2px #958f88"
              }}>
                <div className="circle">
                  <IoIosClose className="text-center" style={{ background: GeneralValues.blueColor }}></IoIosClose>
                </div>

                <Card.Body style={{ marginTop: "-15px" }}>
                  <Card.Title style={{ color: GeneralValues.mustard }}>{this.state.dashboardData.tommdis} </Card.Title>
                  <Card.Text style={{ color: GeneralValues.garland, fontSize: 13, fontWeight: "bold" }}>
                    Disconnections - Tomorrow
    </Card.Text>

                </Card.Body>
              </Card>
            </Col>



            {/* second Row */}








            <Col md={2} sm={6} xs={12} className="my-4" style={{ cursor: "pointer" }} onClick={() => { this.props.moveToUnmappedSTB() }}>
              <Card style={{
                width: '12rem', textAlign: "center", boxShadow: "0.5px 0.5px 2px 2px #958f88"
              }}>
                <div className="circle">
                  <MdBusinessCenter className="text-center" style={{ background: GeneralValues.blueColor }}></MdBusinessCenter>
                </div>

                <Card.Body style={{ marginTop: "-15px" }}>

                  <Card.Title style={{ color: GeneralValues.mustard }}>{this.state.dashboardData.instock}  </Card.Title>
                  <Card.Text style={{ color: GeneralValues.garland, fontSize: 13, fontWeight: "bold" }}>
                    In Stock
    </Card.Text>

                </Card.Body>
              </Card>
            </Col>

            <Col md={2} sm={6} xs={12} className="my-4" style={{ cursor: "pointer" }} onClick={() => this.props.moveToSearchWithQuery(" ")}>
              <Card style={{
                width: '12rem', textAlign: "center",
                boxShadow: "0.5px 0.5px 2px 2px #958f88"
              }}>
                <div className="circle">
                  <FaUserAlt className="text-center" style={{ background: GeneralValues.blueColor }} ></FaUserAlt>
                </div>

                <Card.Body style={{ marginTop: "-15px", padding: " 1.3rem" }}>
                  <Card.Title style={{ color: GeneralValues.mustard }}>{this.state.dashboardData.totalcustomer} </Card.Title>
                  <Card.Text style={{ color: GeneralValues.garland, fontSize: 13, fontWeight: "bold" }}>
                    Total customer
    </Card.Text>

                </Card.Body>
              </Card>
            </Col>


            {/* <Col md={2} sm={6} xs={12} className="my-4" style={{ cursor: "pointer" }} onClick={() => { this.props.moveToUnPaid("dayAfterTomorrow") }}>
              <Card style={{
                width: '12rem', textAlign: "center", background: "linear-gradient(to right, #8e2de2, #4a00e0)",
                // background:" #607D8B",
                color: " #fff"
              }}>
                <div className="circle">
                <IoIosClose className="text-center" style={{ background: "linear-gradient(to right, rgb(127, 35, 226), rgb(91, 12, 225))" }}></IoIosClose>
                </div>

                <Card.Body style={{ marginTop: "-15px" }}>
                  <Card.Title>{this.state.dashboardData.dayaftertommdis} </Card.Title>
                  <Card.Text>
                    Disconnections - DayAfter tomorrow
    </Card.Text>

                </Card.Body>
              </Card>
            </Col>
            <Col md={2} sm={6} xs={12} className="my-4" style={{ cursor: "pointer" }} onClick={() => { this.props.moveToUnPaid("thisMonth") }}>
              <Card style={{
                width: '12rem', textAlign: "center", background: "linear-gradient(to right, #8e2de2, #4a00e0)",
                // background:" #607D8B",
                color: " #fff"
              }}>
                <div className="circle">
                <IoIosClose className="text-center" style={{ background: "linear-gradient(to right, rgb(127, 35, 226), rgb(91, 12, 225))" }}></IoIosClose>
                </div>

                <Card.Body style={{ marginTop: "-15px" }}>
                  <Card.Title>{this.state.dashboardData.curmonthdis} </Card.Title>
                  <Card.Text>
                    Disconnections - This month
    </Card.Text>

                </Card.Body>
              </Card>
            </Col> */}

          </Row>
        </div>
        {this.logOutFunc()}


      </Rootcontainer>

      // </LoadingOverlay>

    )
  }
}