export const CAS = {
    NSTV_direct: [
        {
            sno: 1,
            model: "HBOX 5C",
            type: "HD",
            cas: "NSTV_direct"
        },
        {
            sno: 2,
            model: "HBOX 2 CP",
            type: "HD",
            cas: "NSTV_direct"
        },
        {
            sno: 3,
            model: "HBOX 2 CE",
            type: "HD",
            cas: "NSTV_direct"
        },
        {
            sno: 4,
            model: "HBOX 2C",
            type: "HD",
            cas: "NSTV_direct"
        },
        {
            sno: 5,
            model: "HBOX 2C MINI",
            type: "HD",
            cas: "NSTV_direct"
        },
        {
            sno: 6,
            model: "MBOX- 6C",
            type: "SD",
            cas: "NSTV_direct"
        },
        {
            sno: 7,
            model: "MBOX 4C",
            type: "SD",
            cas: "NSTV_direct"
        },
        {
            sno: 8,
            model: "ASPIRE 8000 (TR120)",
            type: "HD",
            cas: "NSTV_direct"
        },
        {
            sno: 9,
            model: "ASPIRE 8000 (TR125)",
            type: "HD",
            cas: "NSTV_direct"
        },
        {
            sno: 10,
            model: "ASPIRE 5000 (TR123)",
            type: "SD",
            cas: "NSTV_direct"
        },
        {
            sno: 11,
            model: "EXZA (TR122)",
            type: "HD",
            cas: "NSTV_direct"
        },
        {
            sno: 12,
            model: "CSA025160",
            type: "SD",
            cas: "NSTV_direct"
        },
        {
            sno: 13,
            model: "YAGA",
            type: "SD",
            cas: "NSTV_direct"
        },
        {
            sno: 14,
            model: "HM 900C",
            type: "HD",
            cas: "NSTV_direct"
        },
        {
            sno: 15,
            model: "THD3123",
            type: "HD",
            cas: "NSTV_direct"
        },

    ],
    Titanium: [
        {
            sno: 16,
            model: "ASPIRE 5000 (TR123)",
            type: "SD",
            cas: "Titanium"
        },
        {
            sno: 17,
            model: "LBCA5000-W1 (INDRS117)",
            type: "SD",
            cas: "Titanium"
        }
    ]
}