import React from 'react';
import {
    Rootcontainer, SearchDiv, MainContainer, RootDiv, RootTitle, RootBal, RootRight, Amt, MoreDetails,
    RootLeft, RootValue, CustomerStatus, BtnGroupRight, STBGroup, STBDetails,
    CustomerStatusDiv, ActivPackage, ChannelBox, ChannelName, ChannelPrice, AddPackageHeading, UnmappedHeading

} from './styled';
import loadinggif from '../../assets/loding-gif.gif';
import { Redirect } from 'react-router-dom';
import logo from '../../assets/logo-3.png';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import ActivityLogDialog from './ActivityLogDialog';
import GeneralValues from '../../GobelElements/Values';

import { FormControl, FormGroup, FormLabel, Container, ControlLabel, HelpBlock, Checkbox, Radio, Button, InputGroup, Form, Badge, Row, Col } from 'react-bootstrap';
import {
    MdPermIdentity,
    MdHome,
    MdFiberPin,
    MdCreditCard,
    MdRefresh,
    MdPowerSettingsNew,
    MdArrowBack,
    MdAdd,
    MdRemoveCircleOutline,
    MdRemoveCircle,
    MdAddCircle,
    MdAddCircleOutline,
    MdDone,
    MdError,
    MdLiveTv,
    MdModeEdit,
    MdRotate90DegreesCcw,
    MdTimer


} from 'react-icons/md';
import Drawer from 'react-drag-drawer';
import API from '../../Api';
import Header from '../../GobelElements/Header';
import { Card } from 'react-bootstrap';
import {
    FaSearch, FaSpinner, FaSleigh
} from 'react-icons/fa';
import AddDialogPack from './AddPackageDialog';
import RemoveConfirmDialogPack from './RemovePackageConfirmDialog';
import RemoveDialogPack from './RemovePackageDialog';
import TransactionsHistory from './TransactionsHistory';
import '../../screens/CustomerDetails/index.css';
import AddPackConfirmDialog from './AddPackConfirmDialog';
import TickIMG from '../../assets/tick.jpg';
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import DialogBoxs from './DialogBoxs';
import DialogBoxes2 from './DialogBoxes2';
import EditCustomerDialog from './EditCustomerDetails';
import Undraw from "react-undraw";
import View360Components from './View360Dialogs';
import { FaPlus, FaCircle } from "react-icons/fa";
import Loader from 'react-loader-spinner';
import SchedulerTableDialog from './schulerList';


export default class index extends React.Component {
    constructor(props) {
        super(props);

        console.log("data from search", this.props.specifyUserDetails);
        const data = this.props.specifyUserDetails;
        console.log("data in customer details", data);
        let newData = this.props.specifyUserDetails;
        for (var i = 0; i < newData.stbs.length; i++) {
            newData.stbs[i].toggle = false;
        }
        console.log("data from nav", newData);
        console.log("new data", newData)
        // this.getSingleUserDetails(data.Customernum);
        // this.getSingleUserDetails(data.Customernum);
        this.state = {
            userMetaData: data,
            userSTBDetails: {},
            currentChecked: [],
            currentCheckedRemove: [],
            currentPack: [],
            unmappedSTB: [],
            isShowAddPackageDialogBox: false,
            isShowSuccessAddPackDialog: false,
            isShowRemovePackageDialogBox: false,
            isShowAddSTBDialogBox: false,
            addPackageSearchResultList: [],
            allTransactionsResult: [],
            spinnerVisible: false,
            isConfirmationRemove: false,
            isShowRemoveSTBDialogBox: false,
            activeSTBBtn: false,
            isConfirmation: false,
            pageCount: 1,
            totalCount: 0,
            lcoID: localStorage.getItem("userID"),
            lcoPassword: localStorage.getItem("userPassword"),
            domainName: localStorage.getItem("domain"),
            lcoList: JSON.parse(localStorage.getItem("lcolist")),
            packageAvailable: [],
            addPackageSelected: [],
            removePackageSelected: [],
            removePackageSearchResultList: [],
            addSTB: '',
            addSTBCust: '',
            customernumber: '',
            removePackageDetails: [],
            conformADD_PackageList: [],
            conformRemove_PackageList: [],
            isVisibleConformADD_Dialog: false,
            isVisibleConformRemove_Dialog: false,
            isVisibleTransactions_Dialog: false,
            isVisibleRemove_Dialog: false,
            valuePack: 0,
            removeValuePack: 0,
            isShowSuccessDialog: false,
            isEmptyErrorInRemovePackDialog: "",
            isEmptyErrorInRemovePackConformDialog: "",
            isEmptyErrorInAddPackDialog: "",
            isEmptyErrorInAddPackConformDialog: "",
            selectedPackPrice: 0,
            selectedPackPriceRemove: 0,
            isProcess: data.process,
            isNewCustmerAddPack: false,
            isShowDuplicationDialog: false,
            isShowChannelsListDialog: false,
            DuplicationData: [],
            packageList: [],
            channelsList: [],
            activityLogData: [],
            isVisibleActivityLogDialog: false,
            totalDataInActivityLog: 0,
            ActivityLogPageCount: 1,
            isShowLogOutDialog: false,
            isShowCustomerDialog: false,
            isShowEditCustomerSuccessDialog: false,
            isSpinnerVisible: false,
            isSpinnerShow: false,
            isShowActivateWarning: false,
            isShowDeactivateWarning: false,
            modData: [],
            lcoSearchResult: [],
            isShowLcoSearchDialog: false,
            selectedSTBForTransfer: "",
            isShowSTBselectionForTransfer: false,
            isStockUser: localStorage.getItem("isShowStock"),
            isEmptyList: false,
            isShowChannelList: false,
            isDaily: false,
            isShowExtendDialog: false,
            selectedAmount: 0,
            taxRate: "",
            startDate: "",
            endDate: "",
            isShowProData: false,
            isShowResumeDialog: false,
            isShowSuspendWarning: false,
            isShowResumeWarning: false,
            View360Data: [],
            isShow360ContainerDialog: false,
            transferHistory: [],
            productLogs: [],
            settoplogs: [],
            invoiceLogs: [],
            histroyLogs: [],
            msoPackList: [],
            basePackList: [],
            baseTierPackList: [],
            broadcasterPackList: [],
            ALaCarte: [],
            selectedCategoryList: [],
            isOTPEnabled: "",
            stbChannelList: [],
            isShowLowBalanceDialog: false,
            inStockListSearchText: "",
            inStockDataList: [],
            addSchedulerDate: new Date(),
            isShowAddPackageSchedulerDialog: false,
            isShowSchedulerListDialog: false,
            schedulerDataList: [],
            isShowRemovePackageSchedulerDialog: false,
            isShowDuplicationChannelInAddPageDialog: false,
            selectedPackageID: "",
            isAddRemoveSTBAvailable: localStorage.getItem("isShowAddorRemoveBtn")










        }
        console.log("data ", this.state.userMetaData);
        // this.getSingleUserDetails();
        //   this.onData();
        console.log("eeee", this.state.isAddRemoveSTBAvailable ? "true" : "false");
        debugger;
        this.getLCO_Details();
        window.addEventListener('scroll', this.handleScroll);

        let arr = [];
        for (var i = 0; i < this.state.lcoList.length; i++) {
            arr.push({ "id": this.state.lcoList[i].lconame + " " + this.state.lcoList[i].lcocode, "value": this.state.lcoList[i].lcocode, "name": this.state.lcoList[i].lconame })
        }
        console.log("data in kk", arr);
        this.state.modData = arr;
        this.state.lcoSearchResult = arr;
        this.setState({ modData: arr, lcoSearchResult: arr });
        //   debugger;
        //    this.setState({spinnerVisible:true});
        //    setTimeout(() => {
        //     debugger;
        //     this.setState({isSpinnerVisible:false});
        //    }, 8000);
        // this.state.isAddRemoveSTBAvailable = localStorage.getItem("isShowAddorRemoveBtn");
    }
    logOutFunc() {
        if (this.state.isloggedOut) {
            return <Redirect to="/login" />
        } else {
            return null;
        }
    }
    hindLowBalanceDialog() {
        this.setState({ isShowLowBalanceDialog: false });
    }
    hindSchedulerListDialog() {
        this.setState({ isShowSchedulerListDialog: false });
    }

    hindRemovePackageSchedulerDialog() {
        this.setState({ isShowRemovePackageSchedulerDialog: false })
    }
    logOut() {

        localStorage.removeItem("userID");
        const username = localStorage.getItem("userID");
        if (!username) {
            this.setState({ isloggedOut: true });
        }
    }
    componentDidMount() {
        this.onRefreshData();
    }
    hindDuplicationDialog() {
        this.setState({ isShowDuplicationDialog: false });
    }
    hindActivateWarning() {
        this.setState({ isShowActivateWarning: false });
    }
    hindAddPackageSchedulerDialog() {
        this.setState({ isShowAddPackageSchedulerDialog: false })
    }
    showAddPackageSchedulerDialog() {
        this.setState({ isShowAddPackageSchedulerDialog: true })
    }
    hindEmptyList() {
        this.setState({ isEmptyList: false });
    }
    hindSTbSelectionForTransfer() {
        this.setState({ isShowSTbSelectionForTransfer: false });
    }
    hindDeactivateWarning() {
        this.setState({ isShowDeactivateWarning: false });
    }
    hindChannelListDialog() {
        this.setState({ isShowChannelsListDialog: false });
    }
    hindEditCustomerDialog() {
        this.setState({ isShowCustomerDialog: false });
    }
    hindLcoSearchDialog() {
        this.setState({ isShowLcoSearchDialog: false });
    }
    hindEditCustomerSuccessDialog() {
        this.setState({ isShowEditCustomerSuccessDialog: false });
        this.getSingleUserDetails(this.state.userMetaData.Customernum);
    }
    hindExtendDialog() {
        this.setState({ isShowExtendDialog: false })
    }
    hindSuspendWarning() {
        this.setState({ isShowSuspendWarning: false });
    }

    hindResumeWarning() {
        this.setState({ isShowResumeWarning: false });
    }
    hind360ContainerDialog() {
        this.setState({ isShow360ContainerDialog: false });
    }
    hind360ContainerDialog() {
        this.setState({ isShow360ContainerDialog: false });
    }
    showRemovePackageSchedulerDialog() {
        this.setState({ isShowRemovePackageSchedulerDialog: true })
    }
    get360ViewFunc() {
        debugger;
        this.setState({ isSpinnerVisible: true });
        //const customerNo = this.state.userMetaData.Customernum;
        const { lcoID, lcoPassword, userSTBDetails, domainName } = this.state;
        API.getView360(lcoID, lcoPassword, userSTBDetails.SNo, domainName)
            .then((response) => response.json())
            .then((responsejson) => {
                this.setState({ isSpinnerVisible: false, });

                var arr = Object.keys(responsejson).reduce((preval, key) => {
                    let o = { ...responsejson[key] }
                    preval.push(o);
                    return preval;

                }, [])
                console.log("360 view", responsejson, arr);

                // let arrayPackageDetails = Object.keys(responsejson.products).reduce((prevVal, key) => {
                //     let o = { ...responsejson.products[key], toggleMode: false };
                //     prevVal.push(o);
                //     return prevVal;
                // }, []);
                var transferArr = Object.keys(arr[3]).reduce((preval, key) => {
                    let o = { ...arr[3][key] }
                    preval.push(o);
                    return preval;

                }, [])
                var productLogsArr = Object.keys(arr[0]).reduce((preval, key) => {
                    let o = { ...arr[0][key] }
                    preval.push(o);
                    return preval;

                }, [])
                var settoplogsArr = Object.keys(arr[1]).reduce((preval, key) => {
                    let o = { ...arr[1][key] }
                    preval.push(o);
                    return preval;

                }, [])
                var InvoiceLogsArr = Object.keys(arr[2]).reduce((preval, key) => {
                    let o = { ...arr[2][key] }
                    preval.push(o);
                    return preval;

                }, [])
                var historyLogsArr = Object.keys(arr[4]).reduce((preval, key) => {
                    let o = { ...arr[4][key] }
                    preval.push(o);
                    return preval;

                }, [])
                console.log("Tranfer hsitory", transferArr, productLogsArr, settoplogsArr)

                debugger;
                this.setState({
                    isSpinnerShow: false,
                    View360Data: arr,
                    isShow360ContainerDialog: true,
                    transferHistory: transferArr,
                    productLogs: productLogsArr,
                    settoplogs: settoplogsArr,
                    invoiceLogs: InvoiceLogsArr,
                    histroyLogs: historyLogsArr
                });
                console.log("transeret", this.state.View360Data);
                debugger;




            })
            .catch((err) => {
                console.log("error 222", err);
                this.setState({ isSpinnerVisible: false, errorMessage: "Unable to Process !", });

            });

    }
    getSingleUserDetails(Customernum) {
        this.setState({ isSpinnerVisible: true });
        debugger;
        const { lcoID, lcoPassword, domainName } = this.state;
        API.singleCustomerDetailsAPI(lcoID, lcoPassword, "customersearch", Customernum, 1, domainName)
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("Single user data", responseJson);
                debugger;
                this.state.userMetaData = responseJson;
                this.setState({ userMetaData: responseJson, isSpinnerVisible: false });
            }).catch((error) => {
                console.log("single customer search", error);
                this.setState({ isSpinnerVisible: false });
            })


    }
    componentWillMount() {
        console.log("data g", this.state.userMetaData);
        this.setState({ isSpinnerVisible: true });
        setTimeout(() => {
            this.setState({ isSpinnerVisible: false });
        }, 500)
    }
    submit(e) {
        e.preventDefault();

    }
    toggleChangeInRemovePackConfirmDialog(index) {
        let oldPackage = this.state.conformRemove_PackageList;
        let oldToggleValue = oldPackage[index].toggleMode;
        oldPackage[index].toggleMode = !oldToggleValue;

        this.setState({ conformRemove_PackageList: oldPackage });
        console.log("finish");
        console.log("Selected package", this.state.conformRemove_PackageList[index]);
        debugger;
    }
    inVisibleAllTransactionDialog() {
        this.setState({ isVisibleTransactions_Dialog: false, pageCount: 1 });
    }
    toggleChangeInAddPackConfirmDialog(index) {
        let oldPackage = this.state.conformADD_PackageList;
        let oldToggleValue = oldPackage[index].toggleMode;
        oldPackage[index].toggleMode = !oldToggleValue;

        this.setState({ conformADD_PackageList: oldPackage });
        console.log("finish");
        console.log("Selected package", this.state.conformADD_PackageList[index]);
    }
    enableAddPageConformDialog() {


        let length = this.state.packageAvailable.length;
        let totalPackageValue = 0.00;
        let packageArray = [];
        for (var i = 0; i < length; i++) {
            if (this.state.packageAvailable[i].toggleMode) {
                packageArray.push(this.state.packageAvailable[i])
                totalPackageValue = parseFloat(totalPackageValue) + parseFloat(this.state.packageAvailable[i].baseprice);

            }
        }
        console.log("total amount", totalPackageValue);
        this.setState({ valuePack: totalPackageValue });


        console.log("conformation Package list", packageArray);
        const lenghtArray = packageArray.length;
        if (lenghtArray) {
            this.setState({ conformADD_PackageList: packageArray, isVisibleConformADD_Dialog: true, isEmptyErrorInAddPackDialog: "" });
            console.log("total", this.state.valuePack);
        } else {
            this.setState({ isEmptyErrorInAddPackDialog: "Please select package to process" })
        }
    }

    enableRemovePageConformDialog() {
        // 

        let length = this.state.removePackageDetails.length;
        let totalPackageValue = 0.00;
        let packageArray = [];
        for (var i = 0; i < length; i++) {
            if (this.state.removePackageDetails[i].toggleMode) {
                packageArray.push(this.state.removePackageDetails[i])
                totalPackageValue = parseFloat(totalPackageValue) + parseFloat(this.state.removePackageDetails[i].baseprice);

            }
        }
        console.log("total amount", totalPackageValue);
        this.setState({ removeValuePack: totalPackageValue });


        console.log("conformation Package list", packageArray);
        const lenghtArray = packageArray.length;
        if (lenghtArray) {
            this.setState({ conformRemove_PackageList: packageArray, isVisibleConformRemove_Dialog: true, isEmptyErrorInRemovePackDialog: "" });
            console.log("total", this.state.removeValuePack);
        } else {
            this.setState({ isEmptyErrorInRemovePackDialog: "Please select package to process" })
        }

    }

    daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }

    toggleChangeInAddPackDialog(index, id, name, price, toggleMode, base, channels) {

        var oldset = this.state.packageAvailable;
        this.setState({ isEmptyErrorInAddPackDialog: "", selectedPackageID: id });
        for (var val in oldset) {
            if (oldset[val].id == id) {
                oldset[val].toggleMode = !oldset[val].toggleMode;
            }
        }

        // console.log("working toggle", id, index);
        var taxRate = parseInt(this.state.taxRate)

        // let oldPackage = this.state.addPackageSearchResultList;
        // let oldToggleValue = oldPackage[index].toggleMode;
        // oldPackage[index].toggleMode = !oldToggleValue;

        // this.setState({ addPackageSearchResultList: oldPackage });
        var msoPackListVal = [];
        var basePackListVal = [];
        var baseTierPackListVal = [];
        var broadcasterPackListVal = [];
        var ALaCarteVal = [];
        for (var i of oldset) {
            if (i.Alacarte) {
                ALaCarteVal.push(i);
            }
            if (i.base) {
                basePackListVal.push(i);
            }
            if (i.basetier) {
                baseTierPackListVal.push(i);
            }
            if (i.broadboq) {
                broadcasterPackListVal.push(i);
            }
            if (i.msoboq) {
                msoPackListVal.push(i);
            }
        }

        this.setState({

            msoPackList: msoPackListVal,
            basePackList: basePackListVal,
            baseTierPackList: baseTierPackListVal,
            broadcasterPackList: broadcasterPackListVal,
            ALaCarte: ALaCarteVal,
            packageAvailable: oldset


        });


        // console.log("finish");
        // console.log("Selected package", this.state.addPackageSearchResultList[index]);
        // var length = this.state.packageAvailable.length;
        // var i;
        // for (i = 0; i < length; i++) {
        //     if (id == this.state.packageAvailable[i]) {
        //         var oldPackageList = this.state.packageAvailable;
        //         var oldToggle = this.state.packageAvailable[i].toggleMode;
        //         oldPackageList[i].toggleMode = !oldToggle;

        //         this.setState({ packageAvailable: oldPackageList });

        //     }
        // }
        let { currentChecked } = this.state;
        if (toggleMode != true) {
            console.log("toggle status", toggleMode)

            currentChecked.push({ name: name, price: price, id: id, base: base })
            console.log("pushing", currentChecked);
            this.setState({ addPackageSelected: currentChecked });

            if (channels) {
                let isFound = false;
                for (let m of channels) {
                    for (let k of this.state.stbChannelList) {
                        if (m == k) {
                            // this.setState({isEmptyErrorInAddPackDialog:`Selected package have ${k} channel, but it already present in active package`,isShowDuplicationChannelInAddPageDialog:true})
                            this.setState({ isEmptyErrorInAddPackDialog: `${k}`, isShowDuplicationChannelInAddPageDialog: true })
                            isFound = true;
                            break;
                        }
                    }
                    if (isFound) {
                        break;
                    }
                }
            }



        }
        else {

            for (var i = 0; i < currentChecked.length; i++) {
                if (currentChecked[i].id == id) {
                    currentChecked.splice(i, 1);

                    break;
                }

            }
            this.setState({ addPackageSelected: currentChecked });
        }
        // var date = { currentTime: new Date("MM/DD/YYYY").toLocaleString() };
        // var dateStringFormat= date.currentTime.toString();
        // var arrayOfdateFormat = dateStringFormat.split(`/`);
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        //         var d = new Date();
        // var day = d.getDay();

        console.log("date", today, dd, mm, yyyy);
        debugger;

        let totalPackageValues = 0.00;
        let arrLength = currentChecked.length;
        for (var k = 0; k < arrLength; k++) {
            totalPackageValues = parseFloat(totalPackageValues) + parseFloat(currentChecked[k].price);
        }
        console.log("Total value", totalPackageValues);
        var numberOfDayInMonth = this.daysInMonth(parseInt(mm), parseInt(yyyy))
        if (this.state.userMetaData.daily) {
            var days = 0;
            var end1 = this.state.userMetaData.end.toString();
            var endDate = new Date(end1);
            var todayDate = new Date();
            console.log("Date", endDate, todayDate);
            debugger;
            var remaingTimes = endDate.getTime() - todayDate.getTime();
            days = parseInt(remaingTimes / (1000 * 3600 * 24));
            console.log("No of days", days);
            debugger;

            // console.log("end date",end1);
            // debugger;
            // var end = end1.split("-");
            // console.log("end",end,this.state.userMetaData.end);
            // debugger;
            // if(parseInt(mm)  == parseInt(end[1]) ){
            //     days = parseInt(end[2] - dd);
            // }else if(parseInt(mm)  < parseInt(end[1])){
            //     var noOfMonthsEnd =parseInt(end[1]) - parseInt(mm) ;
            //     if(noOfMonthsEnd == 1){
            //    var d1 = numberOfDayInMonth - dd;
            //    days = parseInt(end[2]) + d1;
            //     }else if(noOfMonthsEnd == 2){
            //         var numberOfDayInNextMonth = this.daysInMonth(parseInt(mm)+1, parseInt(yyyy));
            //         var d2 = numberOfDayInMonth - dd;
            //         days = parseInt(end[2]) + d2;
            //         days += numberOfDayInNextMonth; 
            //     }
            // }


            totalPackageValues = ((totalPackageValues / 30) * days).toFixed(2);
            var val1 = totalPackageValues * (taxRate / 100);
            totalPackageValues = parseInt(totalPackageValues) + parseInt(val1);
            debugger;
        } else {

            var remaingDay = numberOfDayInMonth - parseInt(dd);
            totalPackageValues = ((totalPackageValues / numberOfDayInMonth) * remaingDay).toFixed(2);
            var val2 = totalPackageValues * (taxRate / 100);
            totalPackageValues = (parseFloat(totalPackageValues) + parseFloat(val2)).toFixed(2);
            console.log("date", numberOfDayInMonth, totalPackageValues, remaingDay);
        }
        debugger;
        this.setState({ selectedPackPrice: totalPackageValues });

        if (parseInt(totalPackageValues) > parseInt(this.state.lcoBalance)) {
            this.setState({ isEmptyErrorInAddPackDialog: "Low Balance !" });
        }


    }
    removeThisChannel(id) {
        let { addPackageSelected } = this.state;
        let removePackName = this.state.addPackageSelected;
        for (var i = 0; i < removePackName.length; i++) {
            if (removePackName[i].id == id) {
                removePackName.splice(i, 1);

                break;
            }

        }

        var i;
        for (i = 0; i < this.state.packageAvailable.length; i++) {
            if (id == this.state.packageAvailable[i].id) {
                var oldPackageList = this.state.packageAvailable;
                var oldToggle = this.state.packageAvailable[i].toggleMode;
                oldPackageList[i].toggleMode = !oldToggle;

                this.setState({ packageAvailable: oldPackageList });

            }
        }

        this.setState({ addPackageSelected: removePackName });
        console.log("remove icon", addPackageSelected);
        let totalPackageValues = 0.00;
        let arrLength = removePackName.length;
        for (var k = 0; k < arrLength; k++) {
            totalPackageValues = parseFloat(totalPackageValues) + parseFloat(removePackName[k].price);
        }
        console.log("Total value", totalPackageValues);
        this.setState({ selectedPackPrice: totalPackageValues });
        console.log("working on remove package", this.state.selectedPackPriceRemove);
    }

    removePackDialog(id) {

        let { removePackageSelected } = this.state;
        let removePackName = this.state.removePackageSelected;
        for (var i = 0; i < removePackName.length; i++) {
            if (removePackName[i].id == id) {
                removePackName.splice(i, 1);
                break;
            }
        }

        var i;
        for (i = 0; i < this.state.removePackageDetails.length; i++) {
            if (id == this.state.removePackageDetails[i].productid) {
                var oldPackageList = this.state.removePackageDetails;
                var oldToggle = this.state.removePackageDetails[i].toggleMode;
                oldPackageList[i].toggleMode = !oldToggle;

                this.setState({ removePackageDetails: oldPackageList });

            }
        }

        this.setState({ removePackageSelected: removePackName });
        console.log("remove icon", removePackageSelected)
        let totalPackageValues = 0.00;
        let arrLength = removePackName.length;
        for (var k = 0; k < arrLength; k++) {
            totalPackageValues = parseFloat(totalPackageValues) + parseFloat(removePackName[k].price);
        }
        console.log("Total value", totalPackageValues);
        console.log("wwewewew");
        this.setState({ selectedPackPriceRemove: totalPackageValues });
    }

    toggleChangeInRemovePackDialog(index, id, name, price, toggleMode) {
        debugger;
        var taxRate = parseInt(this.state.taxRate);
        console.log("working toggle", id, index);

        let oldPackage = this.state.removePackageSearchResultList;
        console.log("remove package", this.state.removePackageSearchResultList);
        let oldToggleValue = oldPackage[index].toggleMode;
        oldPackage[index].toggleMode = !oldToggleValue;

        this.setState({ removePackageSearchResultList: oldPackage });
        console.log("finish");
        console.log("Selected package", this.state.removePackageSearchResultList[index]);
        var length = this.state.removePackageDetails.length;
        var i;
        console.log("currentCheckedRemove", this.state.currentCheckedRemove);
        debugger;
        for (i = 0; i < length; i++) {
            if (id == this.state.removePackageDetails[i]) {
                var oldPackageList = this.state.removePackageDetails;
                var oldToggle = this.state.removePackageDetails[i].toggleMode;
                oldPackageList[i].toggleMode = !oldToggle;
                this.setState({ removePackageDetails: oldPackageList });

            }
        }


        let { currentCheckedRemove } = this.state;
        if (toggleMode != true) {
            console.log("toggle status", toggleMode)

            currentCheckedRemove.push({ name: name, price: price, id: id })
            console.log("pushing", currentCheckedRemove);
            this.setState({ removePackageSelected: currentCheckedRemove });

        }
        else {
            console.log("currretn ID", id);
            for (var i = 0; i < currentCheckedRemove.length; i++) {

                if (currentCheckedRemove[i].id == id) {
                    console.log("currretn", currentCheckedRemove[i].id);

                    currentCheckedRemove.splice(i, 1);

                    break;
                }

            }
            this.setState({ removePackageSelected: currentCheckedRemove });
        }
        console.log("data in current", this.state.removePackageSelected);
        let totalPackageValues = 0.00;
        let arrLength = currentCheckedRemove.length;
        for (var k = 0; k < arrLength; k++) {
            totalPackageValues = parseFloat(totalPackageValues) + parseFloat(currentCheckedRemove[k].price);
        }

        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        //         var d = new Date();
        // var day = d.getDay();

        console.log("date", today, dd, mm, yyyy);
        var numberOfDayInMonth = this.daysInMonth(parseInt(mm), parseInt(yyyy))

        if (this.state.userMetaData.daily) {
            var days = 0;
            var end1 = this.state.userMetaData.end.toString();
            var endDate = new Date(end1);
            var todayDate = new Date();
            console.log("Date", endDate, todayDate);
            debugger;
            var remaingTimes = endDate.getTime() - todayDate.getTime();
            days = parseInt(remaingTimes / (1000 * 3600 * 24));
            console.log("No of days", days);
            debugger;

            // console.log("end date",end1);
            // debugger;
            // var end = end1.split("-");
            // console.log("end",end,this.state.userMetaData.end);
            // debugger;
            // if(parseInt(mm)  == parseInt(end[1]) ){
            //     days = parseInt(end[2] - dd);
            // }else if(parseInt(mm)  < parseInt(end[1])){
            //     var noOfMonthsEnd =parseInt(end[1]) - parseInt(mm) ;
            //     if(noOfMonthsEnd == 1){
            //    var d1 = numberOfDayInMonth - dd;
            //    days = parseInt(end[2]) + d1;
            //     }else if(noOfMonthsEnd == 2){
            //         var numberOfDayInNextMonth = this.daysInMonth(parseInt(mm)+1, parseInt(yyyy));
            //         var d2 = numberOfDayInMonth - dd;
            //         days = parseInt(end[2]) + d2;
            //         days += numberOfDayInNextMonth; 
            //     }
            // }


            totalPackageValues = ((totalPackageValues / 30) * days).toFixed(2);
            var val1 = totalPackageValues * (taxRate / 100);
            totalPackageValues = parseInt(totalPackageValues) + parseInt(val1);
            debugger;
        } else {
            var remaingDay = numberOfDayInMonth - parseInt(dd);
            totalPackageValues = ((totalPackageValues / numberOfDayInMonth) * parseInt(remaingDay)).toFixed(2);
            var val2 = totalPackageValues * (taxRate / 100);
            totalPackageValues = (parseFloat(totalPackageValues) + parseFloat(val2)).toFixed(2);
        }
        console.log("date", today, dd, mm, yyyy);

        console.log("Total value", totalPackageValues);
        debugger;
        this.setState({ selectedPackPriceRemove: totalPackageValues });
    }

    backTOCustomerSearch() {
        this.props.moveToBackToCustomerSearch();
    }
    onSearchPackage(searchQuery) {
        // 
        this.setState({ spinnerVisible: true });
        var arr = [];
        var length = this.state.packageAvailable.length;
        var i;
        // console.log("query", searchQuery);
        // console.log("length", length);

        for (i = 0; i < length; i++) {
            console.log("count", i);


            var name = this.state.packageAvailable[i].name.toLowerCase();
            var searchCapitals = searchQuery.toLowerCase()
            var number = name.search(searchCapitals);

            console.log("couunt", i, name.search(searchQuery));
            if (number >= 0) {

                arr.push(this.state.packageAvailable[i])


            }

        }
        console.log("array123", arr);
        this.setState({ addPackageSearchResultList: arr, spinnerVisible: false })
    }
    onRemoveSearchPackage(searchQuery) {

        this.setState({ spinnerVisible: true })
        var arr = []

        var length = this.state.removePackageDetails.length;
        var i;
        // console.log("query", searchQuery);
        // console.log("length", length);

        for (i = 0; i < length; i++) {
            console.log("count", i);


            var name = this.state.removePackageDetails[i].name.toLowerCase();
            var searchCapitals = searchQuery.toLowerCase()
            var number = name.search(searchCapitals);

            console.log("couunt", i, name.search(searchQuery));
            if (number >= 0) {

                arr.push(this.state.removePackageDetails[i]);


            }

        }
        console.log("array123", arr);
        this.setState({ removePackageSearchResultList: arr, spinnerVisible: false })
    }

    onRefreshData() {
        console.log("workingg");

        this.setState({ isSpinnerShow: true });
        debugger;
        const { lcoID, lcoPassword } = this.state;
        const customerNo = this.state.userMetaData.Customernum;

        API.singleCustomerDetailsAPI(lcoID, lcoPassword, "customersearch", customerNo, 1, this.state.domainName)
            .then((response) => response.json())
            .then((responsejson) => {
                console.log("Added", responsejson);
                debugger;
                this.setState({
                    startDate: responsejson.start,
                    endDate: responsejson.end,
                    isDaily: responsejson.daily,
                    userMetaData: responsejson,
                    isSpinnerShow: false,
                    userSTBDetails: {},
                    currentPack: [],
                    isProcess: responsejson.process,
                    selectedAmount: responsejson.baseprice,
                    taxRate: responsejson.taxrate,
                    isShowResumeDialog: responsejson.suspend


                });
                if (responsejson.process) {
                    setTimeout(() => {
                        this.onRefreshData();
                    }, 5000)
                }
                console.log(responsejson);
                debugger;
                // this.getLCO_Details();
                // setTimeout(() => {
                //     debugger;
                //     this.setState({spinnerVisible:false});
                // }, 1000);
            })
            .catch((err) => {
                console.log("error", err);
                this.setState({ spinnerVisible: false, isShowAddPackageDialogBox: false, errorMessage: err });
            });
    }
    removePackageSchedule(date1) {
        const lenghtArray = this.state.removePackageSelected.length;
        console.log("workig", this.state.removePackageSelected);
        let arrayOfID = [];
        for (var k = 0; k < lenghtArray; k++) {
            arrayOfID.push(this.state.removePackageSelected[k].id);
        }
        debugger;
        if (lenghtArray) {
            const STBNumber = this.state.userSTBDetails.SNo;
            const { lcoID, lcoPassword } = this.state;
            API.addPackageRemoveScheduler(lcoID, lcoPassword, STBNumber, arrayOfID, date1)
                .then((response) => response.json())
                .then((responsejson) => {
                    console.log("Added", responsejson);
                    debugger;
                    this.setState({ spinnerVisible: false, isVisibleConformRemove_Dialog: false, errorMessage: "", isShowRemovePackageDialogBox: false, isShowSuccessDialog: true, });
                    this.getLCO_Details();
                    this.onRefreshData();
                })
                .catch((err) => {
                    console.log("error", err);
                    this.setState({ spinnerVisible: false, isShowAddPackageDialogBox: false, errorMessage: "", errorMessageForAddPackage: "Unable to Process !" });
                });
        }
    }
    removePackage() {

        // console.log("addpackageResult12345678",this.state.addPackageSearchResultList);

        const lenghtArray = this.state.removePackageSelected.length;
        console.log("workig", this.state.removePackageSelected);
        let arrayOfID = [];
        for (var k = 0; k < lenghtArray; k++) {
            arrayOfID.push(this.state.removePackageSelected[k].id);
        }
        debugger;
        if (lenghtArray) {
            console.log("remove packages", this.state.removePackageSelected)
            this.setState({ spinnerVisible: true });
            const { lcoID, lcoPassword } = this.state;
            const STBNumber = this.state.userSTBDetails.SNo;
            const customerNo = this.state.userMetaData.Customernum;
            API.removePackageAPI(lcoID, lcoPassword, customerNo, "bulkremovepackage", STBNumber, arrayOfID, this.state.domainName)
                .then((response) => response.json())
                .then((responsejson) => {
                    console.log("remove package", responsejson);
                    this.setState({ spinnerVisible: false, isVisibleConformRemove_Dialog: false, errorMessage: "", isShowRemovePackageDialogBox: false, isShowSuccessDialog: true, });
                    this.getLCO_Details();
                    this.onRefreshData();
                })
                .catch((err) => {
                    console.log("error", err);
                    this.setState({ spinnerVisible: false, isVisibleConformRemove_Dialog: false, errorMessage: "Unable to process !", isShowRemovePackageDialogBox: false, });
                    // this.onRefreshData();
                });
        } else {
            this.setState({ isEmptyErrorInRemovePackConformDialog: "Please select package to process " })
        }

    }
    addPackageSchedule(date) {
        let length = this.state.addPackageSelected.length;
        let packageArray = [];
        this.setState({ spinnerVisible: true, isShowAddPackageDialogBox: false });
        const { lcoID, lcoPassword, addSchedulerDate } = this.state;
        for (var i = 0; i < length; i++) {

            packageArray.push(this.state.addPackageSelected[i].id)

        }
        let STBNumber = 0;
        if (this.state.addSTB) {
            STBNumber = this.state.addSTB
        } else {
            STBNumber = this.state.userSTBDetails.SNo;
        }

        API.addPackageAddScheduler(lcoID, lcoPassword, STBNumber, packageArray, date)
            .then((response) => response.json())
            .then((responsejson) => {
                console.log("Added", responsejson);
                debugger;
                this.setState({ spinnerVisible: false, isShowAddPackageDialogBox: false, isVisibleConformADD_Dialog: false, isEmptyErrorInAddPackConformDialog: "", isShowSuccessDialog: true, addSTB: "", isNewCustmerAddPack: false, isEmptyErrorInAddPackDialog: "" });
                this.getLCO_Details();
                // this.onRefreshData();
            })
            .catch((err) => {
                console.log("error", err);
                this.setState({ spinnerVisible: false, isShowAddPackageDialogBox: false, errorMessage: "", errorMessageForAddPackage: "Unable to Process !" });
            });
    }
    getSchedulerList() {
        this.setState({ spinnerVisible: true });
        const { lcoID, lcoPassword } = this.state;
        var STBNumber = 0;
        if (this.state.addSTB) {
            STBNumber = this.state.addSTB
        } else {
            STBNumber = this.state.userSTBDetails.SNo;
        }
        API.getSchedulerList(lcoID, lcoPassword, STBNumber)
            .then((response) => response.json())
            .then((responsejson) => {
                console.log("res", responsejson);
                debugger;
                if (responsejson.length) {
                    for (let m of responsejson) {
                        m.toggleMode = false
                    }
                    this.setState({ isShowSchedulerListDialog: true, schedulerDataList: responsejson });
                }
                this.setState({ spinnerVisible: false });
            })
            .catch(e => {
                console.log("error", e)
                this.setState({ spinnerVisible: false });
            })

    }
    disableScheduler(id) {
        this.setState({ spinnerVisible: true });
        const { lcoID, lcoPassword } = this.state;
        var STBNumber = 0;
        if (this.state.addSTB) {
            STBNumber = this.state.addSTB
        } else {
            STBNumber = this.state.userSTBDetails.SNo;
        }
        API.DisenbleSchedule(lcoID, lcoPassword, STBNumber, id)
            .then((response) => response.json())
            .then((responsejson) => {

                this.setState({ spinnerVisible: false });
                this.getSchedulerList();
            })
            .catch(e => {
                console.log("error", e)
                this.setState({ spinnerVisible: false });
            })

    }
    EnbleScheduler(id) {
        this.setState({ spinnerVisible: true });
        const { lcoID, lcoPassword } = this.state;
        var STBNumber = 0;
        if (this.state.addSTB) {
            STBNumber = this.state.addSTB
        } else {
            STBNumber = this.state.userSTBDetails.SNo;
        }
        API.EnbleSchedule(lcoID, lcoPassword, STBNumber, id)
            .then((response) => response.json())
            .then((responsejson) => {

                this.setState({ spinnerVisible: false });
                this.getSchedulerList();
            })
            .catch(e => {
                console.log("error", e)
                this.setState({ spinnerVisible: false });
            })

    }
    toggleChangeInScheduler(index) {
        let oldData = this.state.schedulerDataList;
        oldData[index].toggleMode = !oldData[index].toggleMode;
        this.setState({ schedulerDataList: oldData })
    }
    addPackage() {

        let length = this.state.addPackageSelected.length;
        let AllowToAdd = false;
        console.log('add package result :', this.state.addPackageSelected)
        let packageArray = [];
        for (var i = 0; i < length; i++) {
            // if (this.state.conformADD_PackageList[i].toggleMode) {
            packageArray.push(this.state.addPackageSelected[i].id)

            // }
        }
        for (var i = 0; i < length; i++) {


            if (this.state.addPackageSelected[i].base) {
                AllowToAdd = true;
                break;
            }
        }


        debugger
        console.log("arr", packageArray);
        const arrayLength = packageArray.length;

        if (arrayLength) {
            if (this.state.isNewCustmerAddPack) {
                if (AllowToAdd) {


                    this.setState({ spinnerVisible: true });
                    const { lcoID, lcoPassword } = this.state;
                    var STBNumber = 0;
                    if (this.state.addSTB) {
                        STBNumber = this.state.addSTB
                    } else {
                        STBNumber = this.state.userSTBDetails.SNo;
                    }
                    const customerNo = this.state.userMetaData.Customernum;

                    API.addPackageAPI(lcoID, lcoPassword, customerNo, "bulkaddpackage", STBNumber, packageArray, this.state.domainName)
                        .then((response) => response.json())
                        .then((responsejson) => {
                            console.log("Added", responsejson);
                            debugger;
                            this.setState({ spinnerVisible: false, isShowAddPackageDialogBox: false, isVisibleConformADD_Dialog: false, isEmptyErrorInAddPackConformDialog: "", isShowSuccessDialog: true, addSTB: "", isNewCustmerAddPack: false, isEmptyErrorInAddPackDialog: "" });
                            this.getLCO_Details();
                            // this.onRefreshData();
                        })
                        .catch((err) => {
                            console.log("error", err);
                            this.setState({ spinnerVisible: false, isShowAddPackageDialogBox: false, errorMessage: "", errorMessageForAddPackage: "Unable to Process !" });
                        });
                } else {
                    this.setState({ isEmptyErrorInAddPackDialog: "Please select one base pack to process" });
                }
            } else {
                this.setState({ spinnerVisible: true });
                const { lcoID, lcoPassword } = this.state;
                var STBNumber = 0;
                if (this.state.addSTB) {
                    STBNumber = this.state.addSTB
                } else {
                    STBNumber = this.state.userSTBDetails.SNo;
                }
                const customerNo = this.state.userMetaData.Customernum;

                API.addPackageAPI(lcoID, lcoPassword, customerNo, "bulkaddpackage", STBNumber, packageArray, this.state.domainName)
                    .then((response) => response.json())
                    .then((responsejson) => {
                        console.log("Added", responsejson);
                        debugger;
                        this.setState({ spinnerVisible: false, isShowAddPackageDialogBox: false, isVisibleConformADD_Dialog: false, isEmptyErrorInAddPackConformDialog: "", isShowSuccessDialog: true, addSTB: "", isNewCustmerAddPack: false, isEmptyErrorInAddPackDialog: "" });
                        this.getLCO_Details();
                        // this.onRefreshData();
                    })
                    .catch((err) => {
                        console.log("error", err);
                        this.setState({ spinnerVisible: false, isShowAddPackageDialogBox: false, errorMessage: "", errorMessageForAddPackage: "Unable to Process !" });
                    });
            }
        } else {
            this.setState({ isEmptyErrorInAddPackDialog: "Please select package to process" });
        }
    }

    onClickAddPackageButton(STB) {
        console.log("STB", STB);
        if (STB) {


            this.setState({ spinnerVisible: true, isShowSuccessDialog: false, isShowSuccessAddPackDialog: false, isShowAddSTBDialogBox: false, addPackageSelected: [], currentChecked: [], selectedPackPrice: 0 });
            const { lcoID, lcoPassword, domainName } = this.state;
            console.log("working12345");
            API.getPackageAPI(lcoID, lcoPassword, "unmapped", STB, domainName)
                .then((response) => response.json())
                .then((responsejson) => {
                    console.log("Success", responsejson);

                    let arrayPackageDetails = Object.keys(responsejson.products).reduce((prevVal, key) => {
                        let o = { ...responsejson.products[key], toggleMode: false };
                        prevVal.push(o);
                        return prevVal;
                    }, []);
                    var msoPackListVal = [];
                    var basePackListVal = [];
                    var baseTierPackListVal = [];
                    var broadcasterPackListVal = [];
                    var ALaCarteVal = [];
                    for (var i of arrayPackageDetails) {
                        if (i.Alacarte) {
                            ALaCarteVal.push(i);
                        }
                        if (i.base) {
                            basePackListVal.push(i);
                        }
                        if (i.basetier) {
                            baseTierPackListVal.push(i);
                        }
                        if (i.broadboq) {
                            broadcasterPackListVal.push(i);
                        }
                        if (i.msoboq) {
                            msoPackListVal.push(i);
                        }
                    }


                    console.log("Arraypackage", arrayPackageDetails)
                    this.setState({
                        isShowAddPackageDialogBox: true,
                        packageAvailable: arrayPackageDetails,
                        addPackageSearchResultList: arrayPackageDetails,
                        spinnerVisible: false,
                        errorMessage: "",
                        msoPackList: msoPackListVal,
                        basePackList: basePackListVal,
                        baseTierPackList: baseTierPackListVal,
                        broadcasterPackList: broadcasterPackListVal,
                        ALaCarte: ALaCarteVal,


                    });


                })
                .catch((err) => {
                    console.log("error", err);
                    this.setState({ spinnerVisible: false, errorMessage: err });
                });
        }

    }
    setCategory(dataList) {
        this.setState({ addPackageSearchResultList: dataList });
    }
    onClickRemovePackageButton(STB) {
        if (STB) {

            this.setState({ isShowRemovePackageDialogBox: true, spinnerVisible: false, removePackageSearchResultList: this.state.currentPack, removePackageDetails: this.state.currentPack, selectedPackPriceRemove: 0.00, removePackageSelected: [] });

        }
    }
    addCustomerSTB() {

        this.setState({ spinnerVisible: true });
        const { lcoID, lcoPassword, domainName } = this.state;
        API.getUnMappedSTB(lcoID, lcoPassword, "unmappedstb", domainName)
            .then((response) => response.json())
            .then((responsejson) => {


                this.setState({ unmappedSTB: responsejson, spinnerVisible: false, isShowAddSTBDialogBox: true });



                console.log("unmappedstb", this.state.unmappedSTB);




            });

    }
    toggle = () => {
        let { toggle } = this.state

        this.setState({ toggle: !toggle })
    }
    handleScroll(e) {

        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {

            this.nextPageData(this.state.allTransactionsResult, (this.state.pageCount + 1), this.state.customernumber);
        }
    }
    scrollBottom(e) {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            console.log("scoller at the end");
        }
    }
    getLCO_Details() {
        this.setState({ isSpinnerVisible: true })
        const { lcoID, lcoPassword, domainName } = this.state;
        API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
            .then((response) => response.json())
            .then((responsejson) => {
                console.log("Respose", responsejson);
                debugger;
                this.setState({
                    lcoName: responsejson.lconame,
                    lcoBalance: responsejson.balance,
                    lcocode: responsejson.lcocode,
                    isSpinnerVisible: false,
                    isShowProData: responsejson.defaultprorata,
                    isShowMantainanceDialog: responsejson.maint,
                    maintText: responsejson.mainttext,
                    maintIMG: responsejson.maintimage,
                    isOTPEnabled: responsejson.otp_edit_customer,


                });

            });
    }

    onClickSTBButton(data, toggelBtn) {
        console.log("data package", data, 'toggle', toggelBtn, 'stb list : ', this.state.userMetaData.stbs.length);
        const data1 = data;
        this.setState({ DuplicationData: data.duplicatechannels, packageList: data.pack });
        let stbBack = [];
        let channelsList = [];
        for (var i = 0; i < this.state.userMetaData.stbs.length; i++) {
            if (this.state.userMetaData.stbs[i].SNo == data1.SNo) {
                this.state.userMetaData.stbs[i].toggle = true;
            }
            else {
                this.state.userMetaData.stbs[i].toggle = false;
            }
        }
        stbBack = this.state.userMetaData;
        if (data.pack) {
            for (let m of data.pack) {

                channelsList = channelsList.concat(m.channels);
                debugger;
            }
        }
        console.log("channels", channelsList);
        debugger;

        this.setState({ userSTBDetails: data1, currentPack: data1.pack, userMetaData: stbBack, DuplicationData: data1.duplicatechannels, stbChannelList: channelsList });
        console.log("data", data1.duplicatechannels, this.state.DuplicationData);
        debugger;
    }

    inVisibleAddPackDialog() {
        this.setState({ isShowAddPackageDialogBox: false })
    }
    inVisibleConfirmPackDialog() {
        this.setState({ isVisibleConformADD_Dialog: false })

    }
    hindDuplicationChannelInAddPageDialog() {
        this.setState({ isShowDuplicationChannelInAddPageDialog: false });
    }
    inVisibleRemovePackDialog() {
        this.setState({ isShowRemovePackageDialogBox: false, conformRemove_PackageList: [], currentCheckedRemove: [] })
    }
    inVisibleRemovePackConformDialog() {
        this.setState({ isVisibleConformRemove_Dialog: false })
    }
    refreshTheCustomer() {
        this.setState({ spinnerVisible: true });

        API.refreshCustomerData(this.state.lcoID, this.state.lcoPassword, "refresh", this.state.domainName, this.state.userMetaData.Customernum)
            .then((response) => response.json())
            .then((responsejson) => {
                console.log("Added", responsejson);
                this.setState({ spinnerVisible: false, isShowSuccessDialog: true, errorMessageForAddPackage: "" });

            })
            .catch((err) => {
                console.log("error", err);
                this.setState({ spinnerVisible: false, errorMessageForAddPackage: "Unable to Process !" })

            });
    }
    editCustomerFunc(name, address, mobileNo, email, pincode) {
        this.setState({ isSpinnerVisible: true, isShowCustomerDialog: false })

        const { lcoID, lcoPassword, domainName, userMetaData } = this.state;
        API.editCustomer(lcoID, lcoPassword, "editcustomer", name, userMetaData.Customernum, address, pincode, email, mobileNo, domainName)
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response customer edit", responseJson);
                this.setState({ isSpinnerVisible: false, isShowEditCustomerSuccessDialog: true });
            }).catch((error) => {
                console.log("error customer edit", error);
                this.setState({ isSpinnerVisible: false });
            })
    }
    pressPayNow(userDetails) {
        if (userDetails.topay) {
            this.setState({ spinnerVisible: true });
            const customerNo = userDetails.Customernum;
            const { lcoID, lcoPassword } = this.state;
            API.payNowAPI(lcoID, lcoPassword, customerNo, "pay", this.state.domainName)
                .then((response) => response.json())
                .then((responsejson) => {
                    this.setState({ spinnerVisible: false, isShowSuccessDialog: true });
                    console.log("logn");
                    console.log("logn");


                    // this.getdata1();
                    this.getLCO_Details();


                })
                .catch((err) => {
                    console.log("error", err);
                    this.setState({ isSpinnerVisible: false, });

                });
        }
    }
    allTransactions(userDetails) {
        this.setState({ spinnerVisible: true });
        this.state.customernumber = userDetails.Customernum;
        const customerNo = userDetails.Customernum;
        const { lcoID, lcoPassword } = this.state;
        API.allTransactionsAPI(lcoID, lcoPassword, customerNo, "transactions", this.state.domainName, 1)
            .then((response) => response.json())
            .then((responsejson) => {
                this.setState({ totalCount: responsejson.page });
                console.log("total count", responsejson.count)
                console.log('all transactions', responsejson.page);
                this.setState({ spinnerVisible: false, isVisibleTransactions_Dialog: true, allTransactionsResult: responsejson.content });
                this.getLCO_Details();


            })
            .catch((err) => {
                console.log("error", err);
                this.setState({ isSpinnerVisible: false, });

            });

    }

    nextPageActivityLog(data, pageNo) {




        let lengthOfallLengthList = this.state.activityLogData.length;
        // console.log("lenght of the list", this.state.totalCount);
        console.log('current page', pageNo, 'total pages', this.state.totalDataInActivityLog)
        if (pageNo <= this.state.totalDataInActivityLog) {

            const { lcoID, lcoPassword } = this.state;

            API.getSTBActivityLog(lcoID, lcoPassword, "stbactivity", this.state.userSTBDetails.SNo, pageNo, this.state.domainName)

                .then((response) => response.json())
                .then((responsejson) => {
                    if (responsejson.count) {
                        console.log("SearchResponse", responsejson);
                        let oldData = data;
                        let newData = oldData.concat(responsejson.content);
                        let count = this.state.ActivityLogPageCount + 1;

                        this.setState({ animating: false, activityLogData: newData, ActivityLogPageCount: count });
                        // console.log("data", this.state.customerList);
                        // if (newData.length < responsejson.count) {

                        //     this.nextPageData(this.state.customerList, (this.state.pageCount + 1));
                        // }
                    }
                })
                .catch((err) => {

                    console.log("error", err);
                    this.setState({ isSpinnerVisible: false });
                    // break


                });
        }
    }
    resumeCustomer() {
        this.setState({ isShowResumeDialog: false, isSpinnerVisible: true })
        var data = this.state.userMetaData;
        const { lcoID, lcoPassword, domainName } = this.state;
        API.resumeAPI(lcoID, lcoPassword, data.Customernum, domainName)
            .then((response) => response.json())
            .then((responsejson) => {
                console.log("response in suspend", responsejson);
                debugger;
                this.setState({ isSpinnerVisible: false })
                this.onRefreshData();
            })

    }
    suspendCustomer() {
        this.setState({ isShowResumeDialog: false, isSpinnerVisible: true })
        var data = this.state.userMetaData;
        const { lcoID, lcoPassword, domainName } = this.state;
        API.suspendAPI(lcoID, lcoPassword, data.Customernum, domainName)
            .then((response) => response.json())
            .then((responsejson) => {
                console.log("response in suspend", responsejson);
                debugger;
                this.setState({ isSpinnerVisible: false })
                this.onRefreshData();
            })

    }

    nextPageData(data, pageNo, customernumber) {



        const customerNo = this.state.customernumber;
        let lengthOfallLengthList = this.state.allTransactionsResult.length;
        // console.log("lenght of the list", this.state.totalCount);
        console.log('current page', pageNo, 'total pages', this.state.totalCount)
        if (pageNo <= this.state.totalCount) {

            const { lcoID, lcoPassword } = this.state;

            API.allTransactionsAPI(lcoID, lcoPassword, customerNo, "transactions", this.state.domainName, pageNo)

                .then((response) => response.json())
                .then((responsejson) => {
                    if (responsejson.count) {
                        console.log("SearchResponse", responsejson);
                        let oldData = data;
                        let newData = oldData.concat(responsejson.content);
                        let count = this.state.pageCount + 1;

                        this.setState({ animating: false, isShowSearchList: true, allTransactionsResult: newData, pageCount: count });
                        // console.log("data", this.state.customerList);
                        // if (newData.length < responsejson.count) {

                        //     this.nextPageData(this.state.customerList, (this.state.pageCount + 1));
                        // }
                    }
                })
                .catch((err) => {

                    console.log("error", err);
                    this.setState({ isSpinnerVisible: false });
                    // break


                });
        }
    }

    renderTheSTB() {
        const { currentPack, channelsList } = this.state;
        if (this.state.userSTBDetails) {
            return (


                <div>
                    <div style={{ fontSize: 17, fontWeight: 600 }}>STB : <label style={{ color: "rgb(39, 0, 255)", fontWeight: 600 }}>{this.state.userSTBDetails.SNo}</label> VC : <label style={{ color: "rgb(39, 0, 255)", fontWeight: 600 }}>{this.state.userSTBDetails.VC}</label></div>
                    <div className="row" style={{ padding: "0px 17px" }}>
                        {currentPack.map((data, i) => {
                            return (
                                <div key={i} className="col-xs-12 col-sm-6 col-md-3 col-lg-2 pack-box">

                                    <ChannelBox
                                        style={{ height: 140, overflow: "auto", border: "2px solid #bdc3c7" }}
                                    >
                                        <ChannelName>{data.name}</ChannelName>
                                        <ChannelPrice>{'\u20B9'} {data.baseprice}</ChannelPrice>
                                        {/* <MdLiveTv
                                            onMouseOverCapture={() => { this.setState({ channelsList: data.channels, isShowChannelList: true }) }}
                                            onMouseLeave={() => { this.setState({ isShowChannelList: false }) }}

                                            color="gray" />


                                        <div className="pack-div">
                                        {/* <MdLiveTv color="gray" /> */}
                                        {/* <a 
                                         onMouseOverCapture={() => { this.setState({ channelsList: data.channels}) }}
                                        className="show-pack">View</a>
                                            <ul>
                                                <a>{this.state.channelsList.map((datap, i) => {

                                                    return (



                                                        <li>{datap}</li>


                                                    )

                                                })}

                                                </a>
                                            </ul>
                                        </div> */}




                                        {/* <div
                                        style={{float:"inline-end"}}
                                            onMouseOverCapture={() => { this.setState({ channelsList: data.channels }) }}
                                            className="pack-div"
                                        > 
                                        <MdLiveTv color="gray"/>
                                       
                                            <ul>
                                               <label style={{color:"red"}}> Channel List :</label> 
                                                <a>{channelsList.map((item) => {
                                                    return (
                                                        <li>{item}</li>
                                                    );
                                                })}
                                                </a>
                                            </ul>

                                        </div> */}


                                        <div
                                            onMouseOverCapture={() => { this.setState({ channelsList: data.channels }) }}

                                            className="above">
                                            <MdLiveTv color="gray" />

                                            <div

                                            // style={{
                                            //     position: "absolute",
                                            //     display: this.state.isShowChannelList ? "block" : "none",
                                            //     listStyle: "none",
                                            //     backgroundColor: "white",
                                            //     padding: 0,
                                            //     boxShadow: "0px 1px 3px grey",
                                            //     zIndex: 9,
                                            //     width: "20vw",
                                            //     top: 50,
                                            //     overflow: "auto",
                                            //     maxHeight: "30vh",

                                            //     marginTop: 69,
                                            // }}
                                            //  onScroll={(e)=>console.log("scrolling",e)}
                                            >
                                                <label style={{ fontSize: 15, fontWeight: "bold", color: "rgb(26, 172, 195)", marginLeft: 10 }}>Channels:</label>
                                                <ul

                                                    style={{ listStyle: "none", padding: 10 }}>
                                                    {this.state.channelsList.map((item, i) => {

                                                        return (
                                                            <li

                                                                style={{ fontSize: 15, color: "black", lineHeight: 2, borderBottom: "1px solid rgba(26, 172, 195,0.5)", cursor: "pointer" }}>
                                                                {item}</li>
                                                        );
                                                    })}
                                                </ul>

                                            </div>



                                        </div>

                                        {/* <div

                                            style={{
                                                position: "absolute",
                                                display: this.state.isShowChannelList ? "block" : "none",
                                                listStyle: "none",
                                                backgroundColor: "white",
                                                padding: 0,
                                                boxShadow: "0px 1px 3px grey",
                                                zIndex: 9,
                                                width: "20vw",
                                                top: 50,
                                                overflow: "auto",
                                                maxHeight: "30vh",
                                                
                                                marginTop: 69,
                                            }}
                                        //  onScroll={(e)=>console.log("scrolling",e)}
                                        >
                                            <label style={{ fontSize: 15, fontWeight: "bold", color: "rgb(26, 172, 195)", marginLeft: 10 }}>Channels:</label>
                                            <ul

                                                style={{ listStyle: "none", padding: 10 }}>
                                                {this.state.channelsList.map((item, i) => {

                                                    return (
                                                        <li
                                                            onScroll={(e) => console.log("scrolling", e)}
                                                            onClick={() => { this.onSelectLco(item.value) }}
                                                            style={{ fontSize: 15, color: "black", lineHeight: 2, borderBottom: "1px solid rgba(26, 172, 195,0.5)", cursor: "pointer" }}>
                                                            {item}</li>
                                                    );
                                                })}
                                            </ul>

                                        </div>  */}
                                    </ChannelBox>
                                </div>
                            );
                        })}
                    </div>
                    <div className="mt-1">



                    </div>
                </div>
            );
        }
    }


    removeCustomerSTB() {
        this.setState({ isShowRemoveSTBDialogBox: true });
    }
    renderRemoveSTB(userdata) {
        debugger;
        if (userdata.stbs.length) {
            {
                userdata.stbs.map((data, i) => {
                    return (
                        <Col sm={6} lg={4} md={4} xs={12} className="mt-3 pl-0" >
                            <Card className="card-shadow">
                                <Card.Body class="pb-0 pl-3    pr-1 pt-2">
                                    <Card.Title>{data.SNo}</Card.Title>
                                    <i className="stbicons" onClick={() => this.renderRemoveSTBClick(data.SNo, data.Customernum)}><MdAdd />Add</i>
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                })
            }
        }
        else {
            return (
                <h5>No STB</h5>
            )
        }
    }
    RemoveSTB(stbno, custno) {
        this.setState({ spinnerVisible: false, isConfirmationRemove: true, isShowRemoveSTBDialogBox: false });

        this.state.addSTB = stbno;
        this.state.addSTBCust = custno;


    }
    isShowDuplication() {
        // console.log("duplicstion", this.state.userSTBDetails.duplicatechannels);
        // var len = this.state.userSTBDetails.duplicatechannels ? 0: this.state.userSTBDetails.duplicatechannels.length;
        if (this.state.userSTBDetails.duplicatechannels) {
            if (this.state.userSTBDetails.duplicatechannels.length) {
                return (
                    <Button variant="danger" size="sm" className="mr-1 ml-1 btn-size" onClick={() => { this.setState({ isShowDuplicationDialog: true }) }}><MdError /> Duplication Found</Button>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
    confirmToRemoveSTB() {
        this.setState({ spinnerVisible: true });
        const { lcoID, lcoPassword } = this.state;
        API.getRemoveSTB(lcoID, lcoPassword, "removestb", this.state.domainName, this.state.addSTBCust, this.state.addSTB)

            .then((response) => response.json())
            .then((responsejson) => {
                console.log("removed stb list :", responsejson)
                this.setState({ spinnerVisible: false, isShowSuccessDialog: true, isShowRemoveSTBDialogBox: false, isConfirmationRemove: false });
            })
    }

    addSTB(stbno, custno) {

        this.state.addSTB = stbno;
        this.state.addSTBCust = custno;
        console.log("addstb : ", this.state.addSTB);
        debugger;
        this.setState({ spinnerVisible: false, isShowAddSTBDialogBox: false, isConfirmation: true });

    }

    confirmToAddSTB() {
        debugger;
        this.setState({ spinnerVisible: true });
        console.log("stb no :", this.state.addSTB, this.state.addSTBCust, this.state.userMetaData)
        const { lcoID, lcoPassword } = this.state;
        API.getaddSTB(lcoID, lcoPassword, "addstb", this.state.domainName, this.state.addSTBCust, this.state.addSTB)

            .then((response) => response.json())
            .then((responsejson) => {
                console.log("removed stb list :", responsejson)
                this.setState({ spinnerVisible: false, isShowSuccessAddPackDialog: true, isNewCustmerAddPack: true, isConfirmation: false });
            })
    }
    getActivityLogFunc(STB) {
        if (STB) {
            this.setState({ isSpinnerVisible: true });
            const { lcoID, lcoPassword, domainName } = this.state;
            API.getSTBActivityLog(lcoID, lcoPassword, "stbactivity", STB, 1, domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("responses", responseJson);
                    this.setState({ isSpinnerVisible: false, isVisibleActivityLogDialog: true, activityLogData: responseJson.content, totalDataInActivityLog: responseJson.count });

                })
        }
    }

    hindActivityLogDialog() {
        this.setState({ activityLogData: [], isVisibleActivityLogDialog: false })
    }

    transferFunc(selectedLcoCode) {
        this.setState({ isSpinnerVisible: true, isShowSTBselectionForTransfer: false });
        const { lcoID, lcoPassword, domainName, selectedSTBForTransfer } = this.state;
        API.customerTransfer(lcoID, lcoPassword, "customertransfer", this.state.userMetaData.Customernum, selectedLcoCode, domainName)
            .then((response) => response.json())
            .then((responseJson) => {

                console.log("response", responseJson);
                debugger;
                if (responseJson.message == "Sucess") {

                    this.setState({ isSpinnerVisible: false });
                    // this.onRefreshData();
                    this.props.moveToDashBoard();
                }
            }).catch((error) => {
                console.log("response", error);
                this.setState({ isSpinnerVisible: false })
            })
    }
    lcoSearchFunc(text) {
        var len = text.length;
        // console.log("length", len, this.state.modData[0].id);
        if (len) {
            var dummy = [];
            var length = this.state.lcoList.length;
            var searchText = text.toString().toLowerCase();
            console.log("data", length, len);
            for (var i = 0; i < length; i++) {

                var val = ""
                val = this.state.modData[i].id.toString().toLowerCase();
                // var lower = val.toString();
                // console.log("lower",val,searchText);

                var n = val.search(searchText);
                if (n != -1) {
                    dummy.push(this.state.modData[i]);
                }
            }
            console.log("dummy", dummy);
            this.setState({ lcoSearchResult: dummy });
        }
        // else{
        //   this.setState({isShowLcoList:false});
        // }
    }
    deactivateCustomer() {
        // const customerNo = userDetails.Customernum;
        this.setState({ isSpinnerVisible: true });
        const { lcoID, lcoPassword } = this.state;
        const customerNo = this.state.userMetaData.Customernum;
        API.disconnect(lcoID, lcoPassword, "disconnect", customerNo, this.state.domainName)
            .then((response) => response.json())
            .then((responsejson) => {
                this.setState({ isSpinnerVisible: false, isShowSuccessAddPackDialog: true });
                console.log("logn", responsejson);
                console.log("logn");
                debugger;
                this.onRefreshData();
            })
            .catch((err) => {
                console.log("error 222", err);
                this.setState({ isSpinnerVisible: false, errorMessage: "Unable to Process !", isPayError: true });

            });
    }

    activateCustomer() {
        debugger;
        this.setState({ isSpinnerVisible: true });
        const customerNo = this.state.userMetaData.Customernum;
        const { lcoID, lcoPassword } = this.state;
        API.payNowAPI(lcoID, lcoPassword, customerNo, "pay", this.state.domainName)
            .then((response) => response.json())
            .then((responsejson) => {
                this.setState({ isSpinnerVisible: false, isShowSuccessAddPackDialog: true });
                console.log("logn", responsejson);
                console.log("logn");
                debugger;

                this.onRefreshData();

                // this.getLCO_Details();


            })
            .catch((err) => {
                console.log("error 222", err);
                this.setState({ isSpinnerVisible: false, errorMessage: "Unable to Process !", isPayError: true });

            });

    }
    get360ViewFunc() {
        debugger;
        this.setState({ isSpinnerVisible: true });
        //const customerNo = this.state.userMetaData.Customernum;
        const { lcoID, lcoPassword, userSTBDetails, domainName } = this.state;
        API.getView360(lcoID, lcoPassword, userSTBDetails.SNo, domainName)
            .then((response) => response.json())
            .then((responsejson) => {
                console.log("respose", responsejson);
                debugger;
                this.setState({ isSpinnerVisible: false, });

                var arr = Object.keys(responsejson).reduce((preval, key) => {
                    let o = { ...responsejson[key] }
                    preval.push(o);
                    return preval;

                }, [])
                console.log("360 view", responsejson, arr);

                // let arrayPackageDetails = Object.keys(responsejson.products).reduce((prevVal, key) => {
                //     let o = { ...responsejson.products[key], toggleMode: false };
                //     prevVal.push(o);
                //     return prevVal;
                // }, []);
                var transferArr = Object.keys(arr[3]).reduce((preval, key) => {
                    let o = { ...arr[3][key] }
                    preval.push(o);
                    return preval;

                }, [])
                var productLogsArr = Object.keys(arr[0]).reduce((preval, key) => {
                    let o = { ...arr[0][key] }
                    preval.push(o);
                    return preval;

                }, [])
                var settoplogsArr = Object.keys(arr[1]).reduce((preval, key) => {
                    let o = { ...arr[1][key] }
                    preval.push(o);
                    return preval;

                }, [])
                var InvoiceLogsArr = Object.keys(arr[2]).reduce((preval, key) => {
                    let o = { ...arr[2][key] }
                    preval.push(o);
                    return preval;

                }, [])
                var historyLogsArr = Object.keys(arr[4]).reduce((preval, key) => {
                    let o = { ...arr[4][key] }
                    preval.push(o);
                    return preval;

                }, [])
                console.log("Tranfer hsitory", transferArr, productLogsArr, settoplogsArr)

                debugger;
                this.setState({
                    isSpinnerShow: false,
                    View360Data: arr,
                    isShow360ContainerDialog: true,
                    transferHistory: transferArr,
                    productLogs: productLogsArr,
                    settoplogs: settoplogsArr,
                    invoiceLogs: InvoiceLogsArr,
                    histroyLogs: historyLogsArr
                });
                console.log("transeret", this.state.View360Data);
                debugger;




            })
            .catch((err) => {
                console.log("error 222", err);
                debugger;
                this.setState({ isSpinnerVisible: false, errorMessage: "Unable to Process !", });


            });

    }

    isShowSuspendBtn() {
        if (this.state.isDaily) {
            return (
                <Button
                    style={{ backgroundColor: "#AAA139", borderColor: "#AAA139" }}
                    onClick={() => {


                        this.setState({ isShowSuspendWarning: true });
                    }}
                    size="sm" className="mr-1 ml-1 my-2 btn-size" ><MdCreditCard /> Suspend</Button>
            )
        } else {
            return null;
        }
    }

    getExtendFunc(day) {
        var req = parseInt((this.state.selectedAmount / 30) * day);
        var bal = parseInt(this.state.lcoBalance);
        if (req < bal) {
            this.setState({ isSpinnerVisible: true });
            const { lcoID, lcoPassword, domainName, userMetaData } = this.state;
            API.extendAPI(lcoID, lcoPassword, userMetaData.Customernum, day, domainName)
                .then((response) => response.json())
                .then((responsejson) => {


                    console.log("response", responsejson);
                    debugger;
                    this.setState({ isSpinnerVisible: false });
                    this.onRefreshData();
                })
                .catch((err) => {
                    console.log("error 222", err);
                    this.setState({ isSpinnerVisible: false });

                });
        }

    }
    showLowBalanceDialog() {
        this.setState({ isShowLowBalanceDialog: true });
        debugger;
    }
    WhichBtnShow() {
        var data = this.state.userMetaData;
        var isAllow = false;
        try {
            if (data.stbs[0].pack.length) {
                isAllow = true;
                // console.log("length", data.stbs[0].pack.length);
            } else {
                throw "too low";
            }
        } catch (error) {
            console.log("No STBs")
        }
        if (this.state.userMetaData.connectionstatus == "Live") {
            return (
                <Button
                    onClick={() => {
                        if (this.state.userSTBDetails.SNo) {
                            console.log("data", this.state.currentPack);



                            this.setState({ isShowDeactivateWarning: true })
                        } else {
                            this.setState({ isEmptyList: true })
                        }
                    }}

                    style={{ backgroundColor: "#A72000", borderColor: "#A72000", fontSize: 13, fontWeight: "bold" }}>Deactivate</Button>
            );
        } else {
            if (isAllow) {
                return (
                    <Button
                        onClick={() => {

                            if (this.state.userSTBDetails.SNo) {
                                if (this.state.userMetaData.topay < this.state.lcoBalance) {
                                    this.setState({ isShowActivateWarning: true })
                                } else {
                                    this.setState({ isShowLowBalanceDialog: true });
                                }
                            } else {
                                this.setState({ isEmptyList: true })
                            }
                        }}
                        style={{ backgroundColor: "#226666", borderColor: "#226666", fontSize: 13, fontWeight: "bold" }}>Activate</Button>
                );
            } else {
                return null;
            }
        }
    }
    isshowtransferBTN() {
        if (this.state.isStockUser != "false" && this.state.userMetaData.connectionstatus != "Live") {
            return (
                <Button
                    style={{ backgroundColor: "#AAA139", borderColor: "#AAA139" }}
                    onClick={() => {


                        this.setState({ isShowSTBselectionForTransfer: true })
                    }}
                    size="sm" className="mr-1 ml-1 my-2 btn-size" ><MdCreditCard /> Transfer</Button>
            );
        } else {
            return null;
        }
    }
    isShowPayNowOrExtend(userMetaData) {
        if (userMetaData.daily && userMetaData.connectionstatus == "Live") {
            return (
                <Button
                    onClick={() => {

                        if (this.state.userSTBDetails.SNo) {
                            this.setState({ isShowExtendDialog: true });
                        } else {
                            this.setState({ isEmptyList: true })
                        }
                    }}
                    variant="primary" size="sm" className="mr-1 ml-1 my-2 btn-size" >Extend</Button>
            )
        } else {
            return (
                <Button
                    onClick={() => {
                        if (this.state.userSTBDetails.SNo) {
                            this.pressPayNow(userMetaData)
                        } else {
                            this.setState({ isEmptyList: true })
                        }
                    }}
                    variant="primary" size="sm" className="mr-1 ml-1 my-2 btn-size" ><MdCreditCard /> Pay Now</Button>
            );
        }
    }

    isShowStarAndEndDate(data) {
        if (data.daily) {
            return (
                <div style={{ marginLeft: 15, marginTop: 10 }}>
                    <label >Start Date :  <span style={{ color: "red" }}> {data.start}</span></label>
                    <label>    end Date :  <span style={{ color: "red" }}> {data.end}</span> </label>
                </div>
            );
        } else {
            return null;
        }
    }
    stockListSearch(text) {

        var { lcoID, lcoPassword, domainName, inStockListSearchText } = this.state;
        if (text.length > 2) {
            this.setState({ inStockDataList: [], isShowInStockList: true, isShowStockLoader: true, noData: "" });

            API.stockListSearch(lcoID, lcoPassword, "liststock", text, 1, domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    if (responseJson.count) {


                        this.setState({ inStockDataList: responseJson.content, isShowInStockList: true, inStockCurrentPage: 1, totalCountInStock: responseJson.count, isShowStockLoader: false });
                        console.log("In stock list", this.state.inStockDataList);
                    } else {
                        this.setState({ inStockDataList: [], isShowStockLoader: false, noData: "No data found!" });
                    }
                });
        } else {
            this.setState({ isShowInStockList: false, isShowInStockList: false, isShowStockLoader: false, noData: "" });
        }
    }
    getSTBDetails(item) {
        console.log("item", item);
        if (item.Customer) {
            this.setState({ spinnerVisible: true });
            const { lcoID, lcoPassword, domainName, searchText } = this.state;
            API.STBSearch(lcoID, lcoPassword, "stbsearch", item.SNo, domainName)
                .then((response) => response.json())
                .then((responsejson) => {
                    console.log("response", responsejson);
                    if (responsejson.message == "no data") {
                        this.setState({ spinnerVisible: false });
                    } else {
                        localStorage.setItem("currentlco", item.Lcocode);
                        this.setState({ spinnerVisible: false });
                        this.getLCO_Details();
                        console.log("Customer details:", responsejson);

                        debugger;
                        // this.props.moveToCustomerDetails("customerdetails", responsejson, this.state.searchText);
                        this.setState({ userMetaData: responsejson, isShowInStockList: false }, () => {
                            this.onRefreshData();
                        });

                    }
                });
        }
        else {
            this.setState({ isShowNodataDialog: true })
        }

    }



    render() {
        const { userMetaData } = this.state;


        return (
            <Rootcontainer onScroll={(e) => this.handleScroll(e)}>
                <AddDialogPack
                    addPackageSearchResultList={this.state.addPackageSearchResultList}
                    isShowAddPackageDialogBox={this.state.isShowAddPackageDialogBox}
                    toggleChangeInAddPackDialog={this.toggleChangeInAddPackDialog.bind(this)}
                    enableAddPageConformDialog={this.enableAddPageConformDialog.bind(this)}
                    onSearchPackage={this.onSearchPackage.bind(this)}
                    inVisibleAddPackDialog={this.inVisibleAddPackDialog.bind(this)}
                    isEmptyErrorInAddPackDialog={this.state.isEmptyErrorInAddPackDialog}
                    addPackageSelected={this.state.addPackageSelected}
                    removeThisChannel={this.removeThisChannel.bind(this)}
                    lcoBalance={this.state.lcoBalance}
                    selectedPackPrice={this.state.selectedPackPrice}
                    addPackage={this.addPackage.bind(this)}
                    isDaily={this.state.isDaily}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    msoPackList={this.state.msoPackList}
                    basePackList={this.state.basePackList}
                    baseTierPackList={this.state.baseTierPackList}
                    broadcasterPackList={this.state.broadcasterPackList}
                    ALaCarte={this.state.ALaCarte}
                    setCategory={this.setCategory.bind(this)}
                    packageAvailable={this.state.packageAvailable}
                    selectedPackChannels={this.state.selectedPackChannels}
                    showLowBalanceDialog={this.showLowBalanceDialog.bind(this)}
                    showAddPackageSchedulerDialog={this.showAddPackageSchedulerDialog.bind(this)}
                    isShowDuplicationChannelInAddPageDialog={this.state.isShowDuplicationChannelInAddPageDialog}
                    hindDuplicationChannelInAddPageDialog={this.hindDuplicationChannelInAddPageDialog.bind(this)}
                    selectedPackageID={this.state.selectedPackageID}



                />
                <AddPackConfirmDialog
                    isVisibleConformADD_Dialog={this.state.isVisibleConformADD_Dialog}
                    conformADD_PackageList={this.state.conformADD_PackageList}
                    toggleChangeInAddPackConfirmDialog={this.toggleChangeInAddPackConfirmDialog.bind(this)}
                    addPackage={this.addPackage.bind(this)}
                    inVisibleConfirmPackDialog={this.inVisibleConfirmPackDialog.bind(this)}
                    isEmptyErrorInAddPackConformDialog={this.state.isEmptyErrorInAddPackConformDialog}
                >

                </AddPackConfirmDialog>

                <RemoveDialogPack
                    removePackageSearchResultList={this.state.removePackageSearchResultList}
                    isShowRemovePackageDialogBox={this.state.isShowRemovePackageDialogBox}
                    inVisibleRemovePackDialog={this.inVisibleRemovePackDialog.bind(this)}
                    onRemoveSearchPackage={this.onRemoveSearchPackage.bind(this)}
                    toggleChangeInRemovePackDialog={this.toggleChangeInRemovePackDialog.bind(this)}
                    enableRemovePageConformDialog={this.enableRemovePageConformDialog.bind(this)}
                    isEmptyErrorInRemovePackDialog={this.state.isEmptyErrorInRemovePackDialog}
                    removePackageSelected={this.state.removePackageSelected}
                    removePackDialog={this.removePackDialog.bind(this)}
                    removePackage={this.removePackage.bind(this)}
                    selectedPackPriceRemove={this.state.selectedPackPriceRemove}
                    lcoBalance={this.state.lcoBalance}
                    isShowProData={this.state.isShowProData}
                    showRemovePackageSchedulerDialog={this.showRemovePackageSchedulerDialog.bind(this)}

                />
                <RemoveConfirmDialogPack
                    isVisibleConformRemove_Dialog={this.state.isVisibleConformRemove_Dialog}
                    conformRemove_PackageList={this.state.conformRemove_PackageList}
                    toggleChangeInRemovePackConfirmDialog={this.toggleChangeInRemovePackConfirmDialog.bind(this)}
                    inVisibleRemovePackConformDialog={this.inVisibleRemovePackConformDialog.bind(this)}
                    removePackage={this.removePackage.bind(this)}
                    isEmptyErrorInRemovePackConformDialog={this.state.isEmptyErrorInRemovePackConformDialog}
                />
                <TransactionsHistory
                    isVisibleTransactions_Dialog={this.state.isVisibleTransactions_Dialog}
                    allTransactionsResult={this.state.allTransactionsResult}
                    inVisibleAllTransactionDialog={this.inVisibleAllTransactionDialog.bind(this)}
                />
                <ActivityLogDialog
                    activityLogData={this.state.activityLogData}
                    isVisibleActivityLogDialog={this.state.isVisibleActivityLogDialog}
                    hindActivityLogDialog={this.hindActivityLogDialog.bind(this)}
                    nextPageActivityLog={this.nextPageActivityLog.bind(this)}
                    ActivityLogPageCount={this.state.ActivityLogPageCount}
                />
                <DialogBoxs
                    duplicationData={this.state.DuplicationData}
                    hindDuplicationDialog={this.hindDuplicationDialog.bind(this)}
                    hindChannelListDialog={this.hindChannelListDialog.bind(this)}
                    isShowDuplicationDialog={this.state.isShowDuplicationDialog}
                    packageList={this.state.packageList}
                    isShowChannelsListDialog={this.state.isShowChannelsListDialog}
                />
                <EditCustomerDialog
                    isShowCustomerDialog={this.state.isShowCustomerDialog}
                    hindEditCustomerDialog={this.hindEditCustomerDialog.bind(this)}
                    userMetaData={this.state.userMetaData}
                    editCustomerFunc={this.editCustomerFunc.bind(this)}
                    isShowEditCustomerSuccessDialog={this.state.isShowEditCustomerSuccessDialog}
                    hindEditCustomerSuccessDialog={this.hindEditCustomerSuccessDialog.bind(this)}
                    isOTPEnabled={this.state.isOTPEnabled}
                />
                <DialogBoxes2
                    isShowActivateWarning={this.state.isShowActivateWarning}
                    hindActivateWarning={this.hindActivateWarning.bind(this)}
                    activateCustomer={this.activateCustomer.bind(this)}
                    isShowDeactivateWarning={this.state.isShowDeactivateWarning}
                    hindDeactivateWarning={this.hindDeactivateWarning.bind(this)}
                    deactivateCustomer={this.deactivateCustomer.bind(this)}
                    lcoSearchResult={this.state.lcoSearchResult}
                    lcoSearchFunc={this.lcoSearchFunc.bind(this)}
                    transferFunc={this.transferFunc.bind(this)}
                    isShowLcoSearchDialog={this.state.isShowLcoSearchDialog}
                    hindLcoSearchDialog={this.hindLcoSearchDialog.bind(this)}
                    isShowSTbSelectionForTransfer={this.state.isShowSTbSelectionForTransfer}
                    hindSTbSelectionForTransfer={this.hindSTbSelectionForTransfer.bind(this)}
                    userMetaData={this.state.userMetaData}
                    isEmptyList={this.state.isEmptyList}
                    hindEmptyList={this.hindEmptyList.bind(this)}
                    lcoBalance={this.state.lcoBalance}
                    selectedAmount={this.state.selectedAmount}
                    isShowExtendDialog={this.state.isShowExtendDialog}
                    hindExtendDialog={this.hindExtendDialog.bind(this)}
                    getExtendFunc={this.getExtendFunc.bind(this)}
                    isShowSuspendWarning={this.state.isShowSuspendWarning}
                    hindSuspendWarning={this.hindSuspendWarning.bind(this)}
                    suspendCustomer={this.suspendCustomer.bind(this)}
                    hindResumeWarning={this.hindResumeWarning.bind(this)}
                    isShowResumeWarning={this.state.isShowResumeWarning}
                    resumeCustomer={this.resumeCustomer.bind(this)}
                    isShowMantainanceDialog={this.state.isShowMantainanceDialog}
                    maintIMG={this.state.maintIMG}
                    maintText={this.state.maintText}
                    getLOC_Details={this.getLCO_Details.bind(this)}
                    isShowLowBalanceDialog={this.state.isShowLowBalanceDialog}
                    hindLowBalanceDialog={this.hindLowBalanceDialog.bind(this)}
                    showLowBalanceDialog={this.showLowBalanceDialog.bind(this)}
                    isShowAddPackageSchedulerDialog={this.state.isShowAddPackageSchedulerDialog}
                    hindAddPackageSchedulerDialog={this.hindAddPackageSchedulerDialog.bind(this)}
                    addSchedulerDate={this.state.addSchedulerDate}
                    addPackageSchedule={this.addPackageSchedule.bind(this)}
                    isShowRemovePackageSchedulerDialog={this.state.isShowRemovePackageSchedulerDialog}
                    hindRemovePackageSchedulerDialog={this.hindRemovePackageSchedulerDialog.bind(this)}
                    removePackageSchedule={this.removePackageSchedule.bind(this)}


                />

                <Drawer

                    open={this.state.isShowSuccessDialog}
                    onRequestClose={this.toggle}
                    direction='left'

                >
                    {/* <div>Hey Im inside a drawer!</div> */}
                    <Card className="confirm-popup">

                        <Card.Body>
                            <div style={{ fontSize: 20, fontWeight: 'bold' }}> Request sent</div>
                            {/* <MdDone class="successicons" /> */}
                            <Undraw className="undrawimg" name="completed" />
                            <Button variant="success" className="mt-3 pull-right"
                                onClick={() => {
                                    this.setState({ isShowSuccessDialog: false, conformRemove_PackageList: [], removePackageSelected: [], currentCheckedRemove: [] });
                                    this.onRefreshData()
                                }}
                            >OK</Button>

                        </Card.Body>
                    </Card>

                </Drawer>
                <Drawer

                    open={this.state.isShowSuccessAddPackDialog}
                    onRequestClose={this.toggle}
                    direction='left'

                >
                    {/* <div>Hey Im inside a drawer!</div> */}
                    <Card className="confirm-popup">

                        <Card.Body>
                            <div style={{ fontSize: 20, fontWeight: 'bold' }}> Request sent</div>
                            {/* <MdDone class="successicons" /> */}
                            <Undraw className="undrawimg" name="completed" />
                            <Button variant="success" className="mt-3 pull-right"
                                onClick={() => {
                                    this.setState({ isShowSuccessAddPackDialog: false });
                                    this.onClickAddPackageButton(this.state.addSTB);
                                }}
                            >OK</Button>

                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.state.isConfirmation}
                    onRequestClose={this.toggle}
                    direction='left'

                >
                    {/* <div>Hey Im inside a drawer!</div> */}
                    <Card className="confirm-popup">

                        <Card.Body>
                            <div style={{ fontSize: 20, fontWeight: 'bold' }}> Request sent</div>

                            <div className="mt-3"> <strong>Are Your Sure Want to add STB ?</strong> </div>

                            <Button variant="danger" className="mt-3 pull-right mr-2 ml-2"
                                onClick={() => {
                                    this.setState({ isConfirmation: false })
                                }}
                            >Cancel</Button>
                            <Button variant="success" className="mt-3 pull-right mr-2 ml-2"
                                onClick={() => {
                                    this.confirmToAddSTB()
                                }}
                            >OK</Button>
                        </Card.Body>
                    </Card>

                </Drawer>
                <Drawer

                    open={this.state.isConfirmationRemove}
                    onRequestClose={this.toggle}
                    direction='left'

                >
                    {/* <div>Hey Im inside a drawer!</div> */}
                    <Card className="confirm-popup">

                        <Card.Body>
                            <div style={{ fontSize: 20, fontWeight: 'bold' }}> Request sent</div>

                            <div className="mt-3"><strong> Are Your Sure Want to remove STB ?</strong></div>
                            <Button variant="success" className="mt-3 pull-right ml-2 mr-2"
                                onClick={() => {
                                    this.confirmToRemoveSTB()
                                }}
                            >OK</Button>
                            <Button variant="danger" className="mt-3 pull-right ml-2 mr-2"
                                onClick={() => {
                                    this.setState({ isConfirmationRemove: false })
                                }}
                            >Cancel</Button>

                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.state.isProcess}
                    onRequestClose={this.toggle}
                    direction='left'

                >
                    {/* <div>Hey Im inside a drawer!</div> */}
                    <Card className="processing-div">

                        <Card.Body>
                            <Spinner
                                size={50}
                                color="#ffffff"
                            ></Spinner>

                            <div><strong>Still Processing.......</strong></div>

                        </Card.Body>
                        <Card.Footer>
                            <Button variant="danger" className="mr-3" onClick={() => this.backTOCustomerSearch()}>Back</Button>
                            <Button varient="info" onClick={() => { this.onRefreshData() }}>Click to Refresh</Button>
                        </Card.Footer>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.state.isShowResumeDialog}
                    onRequestClose={this.toggle}
                    direction='left'

                >
                    {/* <div>Hey Im inside a drawer!</div> */}
                    <Card className="processing-div">

                        <Card.Body>
                            <Spinner
                                size={50}
                                color="#ffffff"
                            ></Spinner>

                            <div><strong>Customer Suspend.......</strong></div>

                        </Card.Body>
                        <Card.Footer>
                            <Button variant="danger" className="mr-3" onClick={() => this.backTOCustomerSearch()}>Back</Button>
                            <Button varient="info" onClick={() => { this.setState({ isShowResumeWarning: true }) }}>Resume</Button>
                        </Card.Footer>
                    </Card>

                </Drawer>




                <LoadingOverlay


                    className={this.state.spinnerVisible ? "displaySpinner" : ""}
                    overlay={true}
                    active={this.state.spinnerVisible}
                    spinner={<img src={loadinggif} className="spinnerContent" color="green" style={{ marginLeft: 100, }} />}

                ></LoadingOverlay>

                <LoadingOverlay


                    className={this.state.isSpinnerVisible ? "displaySpinner" : ""}
                    overlay={true}
                    active={this.state.isSpinnerVisible}
                    spinner={<img src={loadinggif} className="spinnerContent" color="green" style={{ marginLeft: 100, }} />}

                ></LoadingOverlay>


                <Drawer
                    open={this.state.isShowLogOutDialog}
                    onRequestClose={this.toggle}
                    direction="left"
                >
                    <Card>
                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 15, fontWeight: "bold" }}>
                                Do you want{" "}
                                <span style={{ fontSize: "1.5em", color: "red" }}>Log out</span>{" "}
                                ?
              </div>
                            <Undraw className="undrawimg" name="monitor" />
                            <Button
                                style={{ marginLeft: 20 }}
                                className="success"
                                className="mt-3 pull-right"
                                onClick={() => {
                                    this.setState({ isShowLogOutDialog: false });
                                }}
                            >
                                Cancel
              </Button>

                            <Button
                                style={{ backgroundColor: "red" }}
                                className="danger"
                                className="mt-3 pull-right"
                                onClick={() => {
                                    // this.props.hindNodataDialog();
                                    this.logOut();
                                }}
                            >
                                OK
              </Button>
                        </Card.Body>
                    </Card>
                </Drawer>


                <div className="header-div">
                    <MdArrowBack onClick={() => this.backTOCustomerSearch()} className="back-button" />
                    <h3 style={{ marginLeft: "98px" }}>Customer Details</h3>
                    <img
                        src={GeneralValues.circlelogo}
                        style={{ float: "left", marginLeft: " -175px" }}
                        className="logo-img"
                        alt="logo"
                        onClick={() => { this.props.moveToDashBoard() }}
                    />

                    <MdPowerSettingsNew
                        onClick={() => {
                            this.setState({ isShowLogOutDialog: true });
                        }}
                        value="Logout"
                        className="fontSize login"
                    />

                    <input type="text" text="Search" placeholder="Global Search..."
                        onChange={(e) => {
                            this.setState({ inStockListSearchText: e.target.value })
                            this.stockListSearch(e.target.value);
                        }}

                        value={this.state.inStockListSearchText}
                        className="input-STB"
                        style={{ backgroundColor: "transparent", borderColor: "transparent", borderBottom: "2px solid white", color: "white", textAlign: "center", fontSize: 15, borderRight: "2px solid white", fontWeight: "bold", alignSelf: "end", marginLeft: "50%" }} />
                    <div style={{
                        position: "absolute",
                        display: this.state.isShowInStockList ? "block" : "none",
                        listStyle: "none",
                        backgroundColor: "white",
                        padding: 0,
                        boxShadow: "0px 1px 3px #e9b42a",
                        zIndex: 9,
                        width: "22vw",
                        top: 60,
                        overflow: "auto",
                        maxHeight: "30vh",
                        marginLeft: "65%"

                    }}
                        onScroll={(e) => this.handleScroll(e)}>
                        <label style={{ fontSize: 15, fontWeight: "bold", color: "rgb(26, 172, 195)", marginLeft: 10 }}>List:</label>
                        <div style={{ textAlign: "center", color: "gray" }}>{this.state.noData}</div>
                        <ul style={{ listStyle: "none", padding: 10 }}>

                            {this.state.inStockDataList.map((item, i) => {

                                return (


                                    <li className="search-list"
                                        onClick={() => { this.getSTBDetails(item) }}
                                        style={{ fontSize: 15, color: "black", lineHeight: 2, borderBottom: "1px solid rgba(26, 172, 195,0.5)", cursor: "pointer" }}>
                                        <div>
                                            <lable style={{ display: "block" }}>
                                                Name:
  {item.Customer ? item.Customer : <FaPlus style={{
                                                    background: " #0a3f65",
                                                    color: "white", width: "20px", height: "20px", padding: "2px", borderRadius: "10px", marginLeft: "8px"
                                                }} />}

                                                <FaCircle style={{
                                                    display: item.status ? "inline-block" : "none",
                                                    height: "12px", float: "right", marginTop: "8px",
                                                    color: item.status == "Live" ? "green" : "red",
                                                }} />


                                            </lable>

                                            <lable style={{ display: "block" }}>STB: {item.SNo}

                                                <div style={{
                                                    fontSize: "12px", display: item.paid ? "inline-block" : "none", float: "right", color: "white",
                                                    padding: "0px 7px", height: "25px",
                                                    backgroundColor: item.paid === "paid" ? "green" : "red",
                                                }}>{item.paid}  </div>
                                            </lable>

                                            <lable style={{ display: "block", fontSize: "12px" }}>Lco: {item.Lconame}

                                                <div style={{ fontSize: "12px", display: "inline-block", float: "right", color: "red" }}>({item.Lcocode})
 </div>
                                            </lable>
                                        </div>
                                    </li>

                                );
                            })}
                        </ul>

                        <Loader
                            visible={this.state.isShowStockLoader}
                            style={{ textAlign: "center", marginBottom: 10, }}
                            type="ThreeDots"
                            color="#00BFFF"
                            height="10"
                            width="20"
                        />
                    </div>

                </div>

                <RootDiv>
                    <RootLeft>
                        <RootTitle>
                            Name :
                    </RootTitle>

                        <RootValue
                            style={{ cursor: "pointer" }}
                            onClick={() => { this.props.onClickProfile() }} >
                            {this.state.lcoName}

                        </RootValue>
                        <div className="lcocode" >

                            (<span style={{ color: "black" }}>Lco code:</span> {this.state.lcocode})
                </div>
                    </RootLeft>

                    <RootRight>
                        <RootTitle>
                            Balance :
</RootTitle>

                        <RootValue>
                            {'\u20B9'} {this.state.lcoBalance}
                        </RootValue>

                    </RootRight>
                </RootDiv>
                <MainContainer>

                    <LoadingOverlay

                        className={this.state.isSpinnerShow ? "displaySpinner" : ""}
                        overlay={true}
                        active={this.state.isSpinnerShow}
                        spinner={<img src={loadinggif} className="spinnerContent" color="green" style={{ marginLeft: 100, }} />}

                    ></LoadingOverlay>
                    <div style={{ fontWeight: "bold", textAlign: "center", color: "red" }}>{this.state.errorMessageForAddPackage}</div>

                    <label style={{ paddingLeft: 7, fontSize: 17, fontWeight: 600 }}>Customer Status</label> <CustomerStatus style={{ color: userMetaData.connectionstatus == "Live" ? "green" : "red" }}>{userMetaData.connectionstatus}</CustomerStatus>
                    <Button style={{
                        background: "#FF5722",
                        borderColor: "#FF5722",
                        color: "white"
                    }}
                        size="sm" className="mr-1 ml-1 btn-size pull-right " onClick={() => { this.onRefreshData() }} ><MdRefresh /> Page  Refresh </Button>

                    <Card className="card-shadow mt-2" style={{
                        backgroundColor: " rgb(247, 243, 243)"
                        // backgroundColor:" #eaeaea"
                    }}>

                        <Card.Body>
                            <CustomerStatusDiv>
                            </CustomerStatusDiv>
                            <Card.Title style={{ display: "flex" }}>
                                <div style={{ flex: "1" }}>
                                    <div className="my-2">
                                        <MdPermIdentity className="details" />
                                        <label> {userMetaData.name}</label></div>
                                    <div className="my-2">
                                        <MdHome className="details" /> <label style={{ display: "inline" }}>{userMetaData.Address}</label></div>
                                    <div>
                                        <MdFiberPin className="details" /> <label>{userMetaData.Customernum}</label></div>

                                    <div className="my-2"><label style={{
                                        color: "rgb(8, 63, 167)",
                                        fontWeight: " bold"
                                    }}>Monthly Bill </label><label style={{ color: "red" }}>{'\u20B9'} {userMetaData.baseprice}</label></div>
                                    {this.isShowStarAndEndDate(userMetaData)}
                                </div>
                                <div>
                                    <MdModeEdit
                                        onClick={() => { this.setState({ isShowCustomerDialog: true, userMetaData: this.state.userMetaData }) }}
                                        style={{ color: "#3F51B5", cursor: "pointer" }} size={25} />
                                    <BtnGroupRight>
                                        {this.WhichBtnShow()}

                                        {this.isshowtransferBTN()}
                                        {this.isShowSuspendBtn()}
                                        <Button
                                            style={{ backgroundColor: "#882D60" }}
                                            variant="info" size="sm" className="mr-1 ml-1 my-2 btn-size" onClick={() => { this.allTransactions(userMetaData) }}>All Transactions</Button>
                                        {this.isShowPayNowOrExtend(userMetaData)}
                                        {this.state.isAddRemoveSTBAvailable == "true"
                                            ?
                                            <Button style={{
                                                backgroundColor: "#AA7B39",
                                                borderColor: "#AA7B39"
                                            }}
                                                // style={{ backgroundColor: "#FFD200" }}
                                                onClick={() => { this.addCustomerSTB() }}

                                                size="sm" className="mr-1 ml-1 my-2 btn-size"><MdAddCircle /> Add STB</Button>
                                            : null
                                        }

                                        {/* 
 <Button style={{ backgroundColor: "#2E4172" }} onClick={() => { this.removeCustomerSTB() }} variant="danger"  size="sm" className="mr-1 ml-1 btn-size"><MdRemoveCircle /> Remove STB
 </Button> */}
                                        {this.state.isAddRemoveSTBAvailable == "true" ?
                                            <Button className="btn-danger mr-1 ml-1 btn-size my-2" onClick={() => { this.removeCustomerSTB() }}
                                                size="sm" ><MdRemoveCircle /> Remove STB</Button>
                                            : null
                                        }




                                        <Button style={{
                                            backgroundColor: "#795548",
                                            borderColor: " #795548"
                                        }}
                                            onClick={() => { this.refreshTheCustomer() }}
                                            variant="secondary"
                                            size="sm" className="mr-1 ml-1  my-2 btn-size"><MdRefresh /> Refresh STB</Button>
                                    </BtnGroupRight>
                                    <Amt style={{ color: "red" }}>     {'\u20B9'}{userMetaData.topay} </Amt>
                                </div>
                            </Card.Title>
                            <Card.Body className="pt-0 pb-0 pl-0 pr-0">
                                <STBGroup>
                                    {this.state.userMetaData.stbs.map((data, i) => {
                                        return (
                                            <Button variant={data.toggle ? "success" : "warning"} className="text-white mr-1" style={{ fontWeight: 600 }} onClick={() => { this.onClickSTBButton(data, this.state.activeSTBBtn) }} key={i}>STB {i + 1} </Button>
                                        );
                                    })}
                                    {/* <Button variant="warning" className="text-white mr-1" style={{fontWeight:600}} onClick={(e) => { this.submit(e) }}>STB 1</Button> 
        <Button variant="warning" className="text-white mr-1"  style={{fontWeight:600}}>STB 2</Button>  */}
                                </STBGroup>
                                <STBDetails>
                                    <ActivPackage className="mt-1">
                                        Active Packages
</ActivPackage>

                                    <BtnGroupRight>
                                        <Button
                                            style={{ backgroundColor: "transparent", border: "transparent" }}
                                            size="sm" className="mr-1 ml-1 btn-size"
                                            onClick={() => {
                                                if (this.state.currentPack.length) {

                                                    this.setState({ isShowChannelsListDialog: true, isShowDuplicationDialog: false })
                                                } else {
                                                    this.setState({ isEmptyList: true })
                                                }

                                            }}><MdLiveTv size={20} color="blue" /></Button>
                                        {this.isShowDuplication()}
                                        <Button style={{ backgroundColor: "#432F75", borderColor: "#432F75" }} size="sm" className="mr-1 ml-1 btn-size" onClick={() => {
                                            if (this.state.userSTBDetails.SNo) {
                                                this.getActivityLogFunc(this.state.userSTBDetails.SNo)
                                            } else {
                                                this.setState({ isEmptyList: true })
                                            }
                                        }}><MdAddCircle /> Activity</Button>
                                        <Button style={{ backgroundColor: "#de34eb", borderColor: "#de34eb" }} size="sm" className="mr-1 ml-1 btn-size" onClick={() => {
                                            if (this.state.userSTBDetails.SNo) {
                                                this.getSchedulerList()
                                            } else {
                                                this.setState({ isEmptyList: true })
                                            }
                                        }}><MdTimer /> Schedules</Button>
                                        <Button variant="success" size="sm" className="mr-1 ml-1 btn-size" onClick={() => {
                                            if (this.state.userSTBDetails.SNo) {
                                                if (this.state.lcoBalance > 1) {
                                                    this.onClickAddPackageButton(this.state.userSTBDetails.SNo);
                                                } else {
                                                    this.setState({ isShowLowBalanceDialog: true });
                                                }

                                            } else {
                                                this.setState({ isEmptyList: true });
                                            }
                                        }}><MdAddCircle /> Add</Button>

                                        <Button className=" mr-1 ml-1 btn-size" style={{
                                            backgroundColor: "#E91E63", color: "white", borderColor: "#E91E63"
                                        }}
                                            onClick={() => {
                                                if (this.state.userSTBDetails.SNo) {
                                                    this.onClickRemovePackageButton(this.state.userSTBDetails.SNo)
                                                } else {
                                                    this.setState({ isEmptyList: true });
                                                }
                                            }}
                                            size="sm" ><MdRemoveCircle /> Remove </Button>
                                        <Button className=" mr-1 ml-1 btn-size" style={{
                                            backgroundColor: "#m91455", color: "white", borderColor: "#m91455"
                                        }}
                                            onClick={() => {
                                                if (this.state.userSTBDetails.SNo) {
                                                    this.get360ViewFunc(this.state.userSTBDetails.SNo);
                                                } else {
                                                    this.setState({ isEmptyList: true });
                                                }
                                            }}
                                            size="sm" ><MdRotate90DegreesCcw /> 360 View </Button>


                                        {/* <Button variant="warning" size="sm" className="mr-1 ml-1 btn-size text-light" onClick={() => { this.onClickRemovePackageButton(this.state.userSTBDetails.SNo) }}><MdRemoveCircle /> Remove</Button> */}
                                    </BtnGroupRight>
                                    {this.renderTheSTB()}

                                </STBDetails>
                            </Card.Body>


                        </Card.Body>
                    </Card>


                    <Drawer

                        open={this.state.isShowAddSTBDialogBox}
                        onRequestClose={this.toggle}
                        direction='left' containerElementClass="popupwidth"
                    >
                        <div style={{ overflow: "auto", background: "#ffffff", maxHeight: "80vh" }}>

                            <UnmappedHeading><MdAddCircle className="popup-icon" />Add STB List</UnmappedHeading>
                            <Row style={{ paddingLeft: 27, margin: "auto" }} className="mt-1">

                                {this.state.unmappedSTB.map((data, i) => {

                                    return (
                                        <Col sm={8} lg={4} md={6} xs={12} className="mt-3 pl-0" >
                                            <Card className="card-shadow">
                                                <Card.Body class="pb-0 pl-3    pr-1 pt-2">
                                                    <Card.Title>{data.fields.SNo}</Card.Title>
                                                    <Card.Title style={{ color: "red" }}><label>Modal : </label>  {data.fields.Model}</Card.Title>
                                                    <i className="stbicons" onClick={() => { this.addSTB(data.fields.SNo, this.state.userMetaData.Customernum) }}><MdAddCircleOutline />Add</i>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )

                                })}
                            </Row>


                            <Button variant="danger" className="pull-right mb-3 mr-3 mt-3" onClick={(e) => this.setState({ isShowAddSTBDialogBox: false })}>Cancel</Button>
                        </div>
                    </Drawer>

                    <Drawer

                        open={this.state.isShowRemoveSTBDialogBox}
                        onRequestClose={this.toggle}
                        direction='left' containerElementClass="popupwidth"
                    >
                        <div style={{ overflow: "auto", background: "#ffffff" }}>

                            <UnmappedHeading><MdRemoveCircle className="popup-icon" />Unmapped STB List</UnmappedHeading>
                            <Row style={{ paddingLeft: 27, margin: "auto", height: "72vh" }} className="mt-1">

                                {userMetaData.stbs.map((data, i) => {
                                    return (
                                        <Col sm={8} lg={4} md={6} xs={12} className="mt-3 pl-0" >
                                            <Card className="card-shadow">
                                                <Card.Body class="pb-0 pl-3    pr-1 pt-2">
                                                    <Card.Title>{data.SNo}</Card.Title>
                                                    <Card.Title style={{ color: "red" }}>{'\u20B9'}  {data.baseprice}</Card.Title>
                                                    <i className="stbicons" onClick={() => this.RemoveSTB(data.SNo, userMetaData.Customernum)}><MdRemoveCircleOutline />Remove</i>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                })}
                            </Row>


                            <Button variant="danger" className="pull-right mb-3 mr-3 mt-3" onClick={(e) => this.setState({ isShowRemoveSTBDialogBox: false })}>Cancel</Button>
                        </div>
                    </Drawer>


                    <Drawer

                        open={this.state.isShowSTBselectionForTransfer}
                        onRequestClose={this.toggle}
                        direction='left' containerElementClass="popupwidth"
                    >
                        <div style={{ overflow: "auto", background: "#ffffff" }}>

                            <UnmappedHeading><MdRemoveCircle className="popup-icon" />Unmapped STB List</UnmappedHeading>
                            <Row style={{ paddingLeft: 27, margin: "auto", height: "72vh" }} className="mt-1">

                                {userMetaData.stbs.map((data, i) => {
                                    return (
                                        <Col sm={8} lg={4} md={6} xs={12} className="mt-3 pl-0" >
                                            <Card className="card-shadow">
                                                <Card.Body class="pb-0 pl-3    pr-1 pt-2">
                                                    <Card.Title>{data.SNo}</Card.Title>
                                                    <Card.Title style={{ color: "red" }}>{'\u20B9'}  {data.baseprice}</Card.Title>
                                                    <Button
                                                        onClick={() => { this.setState({ isShowLcoSearchDialog: true, selectedSTBForTransfer: data.SNo }) }}
                                                        style={{ backgroundColor: "green", borderColor: "green", display: "inline-block", marginLeft: 200, marginBottom: 15 }}>Select</Button>
                                                    {/* <i className="stbicons" ><MdRemoveCircleOutline />Remove</i> */}
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                })}
                            </Row>


                            <Button variant="danger" className="pull-right mb-3 mr-3 mt-3" onClick={(e) => this.setState({ isShowSTBselectionForTransfer: false })}>Cancel</Button>
                        </div>
                    </Drawer>
                    <View360Components
                        isShow360ContainerDialog={this.state.isShow360ContainerDialog}
                        hind360ContainerDialog={this.hind360ContainerDialog.bind(this)}
                        View360Data={this.state.View360Data}
                        transferHistory={this.state.transferHistory}
                        productLogs={this.state.productLogs}
                        selectSTBNo={this.state.userSTBDetails.SNo ? this.state.userSTBDetails.SNo : "null"}
                        settoplogs={this.state.settoplogs}
                        invoiceLogs={this.state.invoiceLogs}
                        histroyLogs={this.state.histroyLogs}
                    />

                    <View360Components
                        isShow360ContainerDialog={this.state.isShow360ContainerDialog}
                        hind360ContainerDialog={this.hind360ContainerDialog.bind(this)}
                        View360Data={this.state.View360Data}
                        transferHistory={this.state.transferHistory}
                        productLogs={this.state.productLogs}
                        selectSTBNo={this.state.userSTBDetails.SNo ? this.state.userSTBDetails.SNo : "null"}
                        settoplogs={this.state.settoplogs}
                        invoiceLogs={this.state.invoiceLogs}
                        histroyLogs={this.state.histroyLogs}
                    />
                    <SchedulerTableDialog
                        isShowSchedulerListDialog={this.state.isShowSchedulerListDialog}
                        schedulerDataList={this.state.schedulerDataList}
                        hindSchedulerListDialog={this.hindSchedulerListDialog.bind(this)}
                        toggleChangeInScheduler={this.toggleChangeInScheduler.bind(this)}
                        disableScheduler={this.disableScheduler.bind(this)}
                        EnbleScheduler={this.EnbleScheduler.bind(this)}

                    />

                </MainContainer>

                {this.logOutFunc()}


            </Rootcontainer>
        );

    }
}